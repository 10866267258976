import React from 'react'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { makeNbeeSteps } from '@features/nbee/utils'
import { Loader } from '@components/Basic/Loader'
import { isInIframe } from '@app/utils/isInFrame'

interface Props {
  activeStepIndex?: number
  bridgeId?: number | string
  loadingText?: string
}

const embeddablePercentage: Record<number, number> = {
  0: 33,
  1: 66,
  2: 100,
}

export const LoadingStep: React.FC<Props> = ({
  activeStepIndex,
  bridgeId,
  loadingText,
}) => {
  const steps = makeNbeeSteps(activeStepIndex, bridgeId)
  const isEmbedded = isInIframe()
  return (
    <PageEditor
      steps={isEmbedded ? undefined : steps}
      activeStep={activeStepIndex}
      hasTopBar={!isEmbedded}
    >
      <Panel
        progressBarPercentage={
          isEmbedded && activeStepIndex
            ? embeddablePercentage[activeStepIndex] || 0
            : undefined
        }
        body={
          <div
            style={{
              position: 'relative',
              height: 400,
            }}
          >
            <Loader $active $size={'large'} $text={loadingText} />
          </div>
        }
      />
    </PageEditor>
  )
}
