import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'
import { MdMode } from 'react-icons/md'
import { ApiBillingInfo, ApiUserInfo } from 'BackendApi'
import { BillingDetailsStyled, ButtonWrapper } from './styled'

interface Props {
  billingInfo?: ApiBillingInfo | undefined
  onEditRequest: () => void
  userInfo: ApiUserInfo
}

export const BillingDetails: React.FC<Props> = ({
  billingInfo,
  onEditRequest,
  userInfo,
}) => {
  const { t } = useTranslation()

  const {
    companyName,
    country = '',
    state = '',
    zip = '',
    phone,
  } = billingInfo ?? {}

  const displayInfo = {
    name: companyName || `${userInfo.firstName} ${userInfo.lastName}`,
    address: [country, state, zip].filter(Boolean).join(', '),
    phone,
  }

  return (
    <>
      <BillingDetailsStyled>
        <div>
          <p>{displayInfo.name}</p>
          <p>{displayInfo.address.trim()}</p>
          <p>{displayInfo.phone}</p>
        </div>
      </BillingDetailsStyled>
      <ButtonWrapper>
        <MdMode size={24} color={'#41a6dc'} />
        <Button
          style={{ margin: '0', padding: '1rem 0.5rem' }}
          onClick={() => {
            onEditRequest()
          }}
          $variant={'link-primary'}
          $size={'large'}
        >
          {t('billing.payment.editBilling')}
        </Button>
      </ButtonWrapper>
    </>
  )
}
