import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { useTranslation, Trans } from 'react-i18next'
import {
  Wrapper,
  ButtonWrapper,
  Header,
  Title,
  Paragraph,
  FooterWrapper,
  Body,
  BodyInner,
  TableHeader,
  ElementWrapper,
  WarningMessage,
} from './styled'
import { IncomingDataTable } from '@components/IncomingDataTable'
import { LoadingStep } from '@features/nbee/IncomingDataContent/LoadingStep'
import { Checkbox } from '@components/Form/Checkbox'
import { ApiIntegrationListenerField } from '@app/@typings/Api/integration'
import { useAppDispatch } from '@app/store/hooks'
import { setIntegrationListenerFields } from '@app/store/actions/IntegrationListenerActions'

export interface Props {
  appLogoUri: string
  isRecognizingFields: boolean
  isHeaderStuck?: boolean
  onContinue: () => void
  onReload: () => void
  resetConfirmingReload: (value: number) => void
  fields: ApiIntegrationListenerField[]
  isConfirmingReload: number
  activeBridges: number
  totalBridges: number
  appName: string
  listenerType: 'url' | 'email'
}

export const IncomingDataTableStep: React.FC<Props> = ({
  appLogoUri,
  isRecognizingFields,
  onContinue,
  isHeaderStuck = false,
  onReload,
  isConfirmingReload,
  fields,
  activeBridges,
  totalBridges,
  resetConfirmingReload,
  appName,
  listenerType,
}) => {
  const { t } = useTranslation()
  const tableRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const [isStuck, setIsStuck] = React.useState(isHeaderStuck)
  const [selectAll, setSelectAll] = useState<boolean>(
    fields.every((field) => field.enabled)
  )
  const warningRef = useRef(null)

  const handleSelectAllChange = () => {
    const newSelectAllState = !selectAll
    setSelectAll(newSelectAllState)

    const updatedFields = fields.map((field) => {
      return { ...field, enabled: newSelectAllState }
    })

    dispatch(
      setIntegrationListenerFields({
        data: { recognizedFields: updatedFields },
      })
    )
  }

  const handleFieldChange = (fieldId: string, enabled: boolean) => {
    const updatedFields = fields.map((field) =>
      field.id === fieldId ? { ...field, enabled: enabled } : field
    )

    dispatch(
      setIntegrationListenerFields({
        data: { recognizedFields: updatedFields },
      })
    )

    const selectAllStatus = updatedFields.every((field) => field.enabled)
    setSelectAll(selectAllStatus)
  }

  const reloadMessages = [
    'incomingDataPopup.ctaReloadWebhook',
    'incomingDataPopup.ctaReloadWebhookWarning',
    'incomingDataPopup.ctaReloadWebhookLastWarning',
  ]

  const isLastStepOfWarning = isConfirmingReload && isConfirmingReload > 0

  useEffect(() => {
    if (totalBridges && activeBridges && isLastStepOfWarning) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [totalBridges, activeBridges, isLastStepOfWarning])

  // TODO: Fix handleScrollEvent ?
  /*
  const handleScrollEvent = () => {
    const tableOffsetTop = tableRef?.current?.offsetTop
    const bodyOffsetTop =
      Math.abs(document.body.getBoundingClientRect().top) + 35

    if (tableOffsetTop && bodyOffsetTop) {
      setIsStuck(tableOffsetTop === bodyOffsetTop)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScrollEvent)
    return () => {
      window.removeEventListener('scroll', handleScrollEvent)
    }
  })
  */

  const showedName = appName

  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' />
      </Header>
      {isRecognizingFields ? (
        <LoadingStep
          appLogoUri={appLogoUri}
          appName={showedName}
          listenerType={listenerType}
        />
      ) : (
        <Body>
          <Title>
            <img src={appLogoUri} width='45' />
            <h2>
              {t('incomingDataPopup.titleSecondStepWebhook', {
                totalFields: fields.length,
                appName: showedName,
              })}
            </h2>
          </Title>
          <Paragraph>
            {t('incomingDataPopup.paragraphSecondStepWebhook')}
          </Paragraph>
          <TableHeader isHeaderStuck={isStuck} ref={tableRef}>
            <ElementWrapper>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 0.1rem',
                }}
              >
                <Checkbox
                  checked={selectAll}
                  name={'checkbox'}
                  onChange={handleSelectAllChange}
                />
              </div>
              <div>Key</div>
              <div>Value</div>
            </ElementWrapper>
          </TableHeader>
          <BodyInner>
            <IncomingDataTable
              fields={fields}
              onFieldChange={handleFieldChange}
            />
            {totalBridges && activeBridges && isLastStepOfWarning ? (
              <WarningMessage ref={warningRef}>
                {t('integrationAuthPopup.integrationUsage', {
                  totalBridges,
                  activeBridges,
                  action: 'Reload',
                })}
              </WarningMessage>
            ) : null}
          </BodyInner>
          <FooterWrapper>
            <ButtonWrapper>
              <Button
                $variant={isLastStepOfWarning ? 'action' : 'secondary'}
                onClick={onReload}
                // style={{ width: '' }}
              >
                <span>
                  <span>{t(reloadMessages[isConfirmingReload] as any)}</span>
                </span>
              </Button>

              <Button
                $variant={'primary'}
                onClick={
                  isLastStepOfWarning
                    ? () => resetConfirmingReload(0)
                    : onContinue
                }
              >
                {isLastStepOfWarning
                  ? t('incomingDataPopup.ctaGoBackWebhook')
                  : t('incomingDataPopup.ctaContinueWebhook')}
              </Button>
            </ButtonWrapper>
          </FooterWrapper>
        </Body>
      )}
    </Wrapper>
  )
}
