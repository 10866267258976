import React, { InputHTMLAttributes } from 'react'
import {
  LabelStyled,
  SpanStyled,
  WrapperStyled,
} from '@components/Form/InputToggle/styled'

export type SpanStyle = 'primary' | 'dark'

export interface InputToggleProps
  extends InputHTMLAttributes<HTMLInputElement> {
  $labelRight?: string
  $labelLeft?: string
  $isChecked?: boolean
  $variant?: SpanStyle
  $size?: 'large' | 'small'
  $isDisabled?: boolean
}

export const InputToggle: React.FC<InputToggleProps> = ({
  $isChecked,
  $variant,
  $labelLeft,
  $labelRight,
  $size,
  $isDisabled,
  ...props
}) => {
  return (
    <WrapperStyled $size={$size} $variant={$variant} $isChecked={$isChecked}>
      {$labelLeft && (
        <label style={{ marginRight: '1rem' }}>{$labelLeft}</label>
      )}
      <LabelStyled $isDisabled={$isDisabled} $size={$size}>
        <input
          disabled={$isDisabled}
          checked={$isChecked}
          type='checkbox'
          {...props}
        />
        <SpanStyled $size={$size} $variant={$variant} $isChecked={$isChecked} />
      </LabelStyled>
      {$labelRight && (
        <label style={{ marginLeft: '1rem' }}>{$labelRight}</label>
      )}
    </WrapperStyled>
  )
}
