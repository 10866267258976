import React from 'react'
import { ProgressDivStyled } from '@components/Basic/ProgressBar/styled'

export interface ProgressProps {
  $progress: number
  $theme: 'primary'
}

export const ProgressBar: React.VFC<ProgressProps> = ({
  $progress,
  $theme,
}) => {
  return <ProgressDivStyled $progress={$progress} $theme={$theme} />
}
