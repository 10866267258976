import React, { lazy, Suspense } from 'react'
import { useGetUserInfo } from '@app/api/getUserInfo'
import { Route, Switch } from 'react-router-dom'
import { appRoutes } from '@app/routes'

const InfoBarComponentLazy = lazy(() => import('./InfoBarComponent'))

const SuperUserInfoBarWithQuery: React.VFC = () => {
  const { data: userData } = useGetUserInfo()

  if (userData?.user && userData?.superUser?.email) {
    return (
      <Suspense fallback={null}>
        <InfoBarComponentLazy
          fullName={`${userData.user.firstName} ${userData.user.lastName}`}
          email={userData.user.email || ''}
        />
      </Suspense>
    )
  }

  return null
}

// In case we are in logout path we can't load the SuperUserInfoBarWithQuery
// because it will call `useGetUserInfo` (user/me) api that will generate an endless loop
// `user/me` returns 401 -> redirect to logout -> calls user/me again -> 401 -> new redirection
export const SuperUserInfoBar: React.VFC = () => {
  return (
    <Switch>
      <Route path={appRoutes.logout.path}>
        <div />
      </Route>
      <Route path='/*' exact>
        <SuperUserInfoBarWithQuery />
      </Route>
    </Switch>
  )
}
