import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InputField } from '@components/Form/InputField'
import { Textarea } from '@components/Form/Textarea'
import { InputToggle } from '@components/Form/InputToggle'
import { useField, useFormikContext } from 'formik'
import { Dropdown } from '@features/nbee/WelcomeMessageForm/DropDown'
import { BridgeFormValues } from 'Nbee'
import { ApiBridgeFieldsList, TemplateBasedFieldsMapping } from 'BackendApi'
import { Button } from '@components/Basic/ButtonNbe'
import { InputSmartCreatableSelect } from '@components/Form/InputSmartSelect/Creatable'
import { FaInfoCircle } from 'react-icons/fa'
import { calculateDropdownPosition } from '@features/nbee/WelcomeMessageForm/utils'
import { InputFeedback } from '@components/Basic/InputFeedback'

export interface VariableObjType {
  id: string
  label?: string
}

interface Props {
  bridgeFields: ApiBridgeFieldsList | undefined
}

export const WelcomeMessageForm: React.FC<Props> = ({ bridgeFields }) => {
  const { t } = useTranslation()
  const dropdownWrapperRef = useRef<HTMLDivElement>(null)
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const charToOpen = '['
  const charToClose = ']'

  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()
  const [allAvailableCoupons, setAllAvailableCoupons] = useState<string[]>([])
  const [subjectField, metaSubject, helpersSubject] = useField(
    'templateBasedFieldsMapping.subject'
  )
  const [bodyField, metaBody, bodyHelpers] = useField(
    'templateBasedFieldsMapping.body'
  )
  const [isCouponActive, , couponActiveHelpers] = useField(
    'templateBasedFieldsMapping.isCouponActive'
  )
  const [couponsField, , couponsHelpers] = useField(
    'templateBasedFieldsMapping.coupons'
  )

  const templateBasedFieldsMapping = values.templateBasedFieldsMapping
  const isBridgeEnabled = values.ui?.isBridgeEnabled

  const subjectError = metaSubject.touched && metaSubject.error
  const bodyError = metaBody.touched && metaBody.error
  const subjectErrorStatus = subjectError ? { error: subjectError } : undefined
  const bodyErrorStatus = bodyError ? { error: bodyError } : undefined

  const initialValues: TemplateBasedFieldsMapping = {
    subject: templateBasedFieldsMapping?.subject ?? 'Welcome on board, ',
    body:
      templateBasedFieldsMapping?.body ??
      'Hi\nHere is your [COUPON]\nHave a nice day',
    isCouponActive: templateBasedFieldsMapping?.isCouponActive ?? false,
    coupons: templateBasedFieldsMapping?.coupons ?? [],
  }

  const [inputSelectPosition, setInputSelectPosition] = useState({ x: 0, y: 0 })
  const [showDropdown, setShowDropdown] = useState(false)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const [inputElement, setInputElement] = useState<HTMLInputElement | null>(
    null
  )
  const [lastFocusedField, setLastFocusedField] = useState<
    'subject' | 'body' | null
  >(null)
  const [isFirstRender, setIsFirstRender] = useState(true)
  useEffect(() => {
    setIsFirstRender(false)
  }, [])

  const [dropdownHeight, setDropdownHeight] = useState(0)

  const [textInsideBrackets, setTextInsideBrackets] = useState('')
  const [filteredData, setFilteredData] = useState<VariableObjType[]>([])

  const source = bridgeFields?.source
  const templateFields = bridgeFields?.templateFields
  const isSmsMessage =
    templateFields && !templateFields.some((field) => field.id === 'subject')

  const couponValue = { id: 'COUPON', label: 'COUPON' }

  useEffect(() => {
    if (!values.templateBasedFieldsMapping) {
      setFieldValue('templateBasedFieldsMapping.subject', initialValues.subject)
      setFieldValue('templateBasedFieldsMapping.body', initialValues.body)
      setFieldValue(
        'templateBasedFieldsMapping.isCouponActive',
        initialValues.isCouponActive
      )
      setFieldValue('templateBasedFieldsMapping.coupons', initialValues.coupons)
    }
  }, [setFieldValue, values.templateBasedFieldsMapping, initialValues])

  useEffect(() => {
    setAllAvailableCoupons([...new Set([...initialValues.coupons])])
  }, [])

  useEffect(() => {
    if (source) {
      const initialData = source.map((item) => ({
        id: item.id.toUpperCase(),
        label: item.label.toUpperCase(),
      }))
      setFilteredData(
        isSmsMessage ? initialData : [couponValue, ...initialData]
      )
    }
  }, [source, isSmsMessage])

  useEffect(() => {
    if (textInsideBrackets) {
      const filtered = source
        ? source
            .filter((item) =>
              item.id.toLowerCase().startsWith(textInsideBrackets.toLowerCase())
            )
            .map((item) => ({
              id: item.id.toUpperCase(),
              label: item.label.toUpperCase(),
            }))
        : []
      setFilteredData(isSmsMessage ? filtered : [couponValue, ...filtered])
    } else {
      const newData = source
        ? source.map((item) => ({
            id: item.id.toUpperCase(),
            label: item.label.toUpperCase(),
          }))
        : []
      setFilteredData(isSmsMessage ? newData : [couponValue, ...newData])
    }
  }, [textInsideBrackets, source, isSmsMessage])

  useEffect(() => {
    if (!isFirstRender && dropdownWrapperRef.current) {
      setDropdownHeight(dropdownWrapperRef.current.clientHeight)
    }
  }, [isFirstRender, dropdownWrapperRef])

  useEffect(() => {
    if (dropdownWrapperRef.current) {
      setDropdownHeight(dropdownWrapperRef.current.clientHeight)
    }
  }, [filteredData])

  useEffect(() => {
    if (!dropdownWrapperRef.current || !textAreaRef.current) return

    const fieldRef =
      lastFocusedField === 'subject' && inputElement
        ? inputElement
        : textAreaRef.current

    const value = fieldRef.value
    const cursorPosition = fieldRef.selectionEnd ?? 0
    const indexOfLastOpenBracket = value.lastIndexOf(charToOpen, cursorPosition)

    const newPosition = calculateDropdownPosition(
      fieldRef,
      value,
      dropdownWrapperRef.current.clientHeight,
      indexOfLastOpenBracket
    )

    setInputSelectPosition(newPosition)
  }, [
    dropdownHeight,
    inputSelectPosition.x,
    inputSelectPosition.y,
    lastFocusedField,
    textInsideBrackets,
    inputElement,
  ])

  useEffect(() => {
    const handleScroll = () => {
      if (!dropdownWrapperRef.current || !inputElement || !textAreaRef.current)
        return

      const fieldRef =
        lastFocusedField === 'subject' ? inputElement : textAreaRef.current
      const value = fieldRef.value
      const cursorPosition = fieldRef.selectionEnd ?? 0
      const indexOfLastOpenBracket = value.lastIndexOf(
        charToOpen,
        cursorPosition
      )

      const newPosition = calculateDropdownPosition(
        fieldRef,
        value,
        dropdownWrapperRef.current.clientHeight,
        indexOfLastOpenBracket
      )

      setInputSelectPosition(newPosition)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastFocusedField, dropdownHeight])

  const setInputRef = (input: HTMLInputElement | null) => {
    setInputElement(input)
  }

  const checkForChar = (
    value: string,
    element: HTMLInputElement | HTMLTextAreaElement | null,
    isClosingBracketDeleted = false
  ) => {
    if (!element) return

    const cursorPosition = element.selectionStart ?? 0
    const indexOfLastOpenBracketBeforeCursor = value.lastIndexOf(
      charToOpen,
      cursorPosition - 1
    )
    const indexOfLastCloseBracketBeforeCursor = value.lastIndexOf(
      charToClose,
      cursorPosition - 1
    )

    if (
      indexOfLastOpenBracketBeforeCursor >
        indexOfLastCloseBracketBeforeCursor ||
      isClosingBracketDeleted
    ) {
      const text = value.substring(
        indexOfLastOpenBracketBeforeCursor + 1,
        cursorPosition
      )
      setTextInsideBrackets(text)
      setShowDropdown(true)

      const newPosition = calculateDropdownPosition(
        element,
        value,
        dropdownHeight,
        indexOfLastOpenBracketBeforeCursor
      )
      setInputSelectPosition(newPosition)
    } else {
      setShowDropdown(false)
    }
  }

  const handleFieldFocus = (field: 'subject' | 'body') => {
    setLastFocusedField(field)
    if (field === 'subject') {
      helpersSubject.setTouched(true)
    } else if (field === 'body') {
      bodyHelpers.setTouched(true)
    }
  }

  const handleOnSelect = (item: VariableObjType) => {
    const fieldToUpdate =
      lastFocusedField === 'subject' ? inputElement : textAreaRef.current
    if (!fieldToUpdate) {
      return
    }

    const helpersToUpdate =
      lastFocusedField === 'subject' ? helpersSubject : bodyHelpers
    const currentText = fieldToUpdate.value

    const cursorPosition = fieldToUpdate.selectionEnd ?? 0
    const indexOfLastOpenBracket = currentText.lastIndexOf(
      charToOpen,
      cursorPosition
    )

    let newText
    let newCursorPosition: number

    if (indexOfLastOpenBracket >= 0) {
      newText =
        currentText.substring(0, indexOfLastOpenBracket) +
        '[' +
        item.id +
        ']' +
        currentText.substring(cursorPosition)
      newCursorPosition = indexOfLastOpenBracket + item.id.length + 2
    } else {
      newText = currentText + '[' + item.id + ']'
      newCursorPosition = currentText.length + item.id.length + 2
    }

    helpersToUpdate.setValue(newText)

    setTimeout(() => {
      fieldToUpdate.focus()
      fieldToUpdate.setSelectionRange(newCursorPosition, newCursorPosition)
    }, 0)

    setShowDropdown(false)
  }

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    if (name === 'subject') {
      helpersSubject.setValue(value)
      checkForChar(value, inputElement)
    } else if (name === 'body') {
      bodyHelpers.setValue(value)
      checkForChar(value, textAreaRef.current)
    }
  }

  useEffect(() => {
    if (showDropdown && highlightedIndex < 0 && filteredData.length > 0) {
      setHighlightedIndex(0)
    }
  }, [showDropdown, highlightedIndex, filteredData])

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      e.preventDefault()
      let newIndex = highlightedIndex
      if (e.key === 'ArrowDown' && highlightedIndex < filteredData.length - 1) {
        newIndex = highlightedIndex + 1
      } else if (e.key === 'ArrowUp' && highlightedIndex > 0) {
        newIndex = highlightedIndex - 1
      }
      setHighlightedIndex(newIndex)

      if (dropdownWrapperRef.current) {
        const selectedItem = dropdownWrapperRef.current.children[newIndex]
        selectedItem?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
    if (e.key === 'Enter' && showDropdown && filteredData.length > 0) {
      e.preventDefault()
      handleOnSelect(filteredData[highlightedIndex])
      setShowDropdown(false)
    } else if (e.key === 'Backspace') {
      const fieldRef =
        lastFocusedField === 'subject' ? inputElement : textAreaRef.current
      if (fieldRef && fieldRef.selectionStart != null) {
        const cursorPosition = fieldRef.selectionStart

        const isClosingBracketDeleted =
          fieldRef.value[cursorPosition - 1] === charToClose

        setTimeout(() => {
          checkForChar(fieldRef.value, fieldRef, isClosingBracketDeleted)
        }, 0)
      }
    }
  }

  const handleAddVariableClick = () => {
    const fieldRef =
      lastFocusedField === 'subject' ? inputElement : textAreaRef.current

    if (fieldRef && document.body.contains(fieldRef)) {
      const cursorPosition = fieldRef.selectionStart || 0

      const value = fieldRef.value
      const newValue =
        value.slice(0, cursorPosition) + '[' + value.slice(cursorPosition)

      const helpersToUpdate =
        lastFocusedField === 'subject' ? helpersSubject : bodyHelpers
      helpersToUpdate.setValue(newValue)

      setTimeout(() => {
        fieldRef.focus()
        fieldRef.setSelectionRange(cursorPosition + 1, cursorPosition + 1)

        checkForChar(newValue, fieldRef)
      }, 0)
    }
  }

  const managePastedData = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedData = e.clipboardData.getData('text')
    const newCoupons = pastedData
      .replace(/\r?\n|\r/g, ' ')
      .split(/[\s,]+/)
      .filter((coupon) => coupon.trim() !== '')

    const updatedCoupons = [...initialValues.coupons, ...newCoupons]
    couponsHelpers.setValue(updatedCoupons)
    setAllAvailableCoupons((prev) => [...new Set([...prev, ...newCoupons])])
  }

  const handleSelectCoupon = (coupons: string[]) => {
    couponsHelpers.setValue(coupons)
    setAllAvailableCoupons((prev) => [...new Set([...prev, ...coupons])])
  }

  return (
    <>
      <div style={{ position: 'relative', marginTop: '1rem' }}>
        {!isSmsMessage && (
          <>
            <InputField
              value={initialValues.subject}
              onKeyDown={handleKeyDown}
              inputRefCallback={setInputRef}
              name={'subject'}
              type={'text'}
              label={'Subject'}
              onChange={handleInputChange}
              onFocus={() => handleFieldFocus('subject')}
              disabled={isBridgeEnabled}
              $hasMargin
            />
            {subjectErrorStatus && (
              <InputFeedback $status={subjectErrorStatus} />
            )}
          </>
        )}
        <Textarea
          value={initialValues.body}
          onKeyDown={handleKeyDown}
          ref={textAreaRef}
          name={'body'}
          type={'text'}
          label={'Body'}
          onChange={handleInputChange}
          onFocus={() => handleFieldFocus('body')}
          style={{ height: '200px', resize: 'none', outline: 'none' }}
          disabled={isBridgeEnabled}
        />
        {bodyErrorStatus && <InputFeedback $status={bodyErrorStatus} />}
        <Button
          $variant={'link-primary'}
          type={'button'}
          onClick={handleAddVariableClick}
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            zIndex: 2,
          }}
          disabled={isBridgeEnabled}
        >
          Add Variable
        </Button>
        <Dropdown
          ref={dropdownWrapperRef}
          items={filteredData}
          onSelect={(item: VariableObjType) => {
            handleOnSelect(item)
          }}
          isVisible={showDropdown}
          position={inputSelectPosition}
          highlightedIndex={highlightedIndex}
          setHighlightedIndex={setHighlightedIndex}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '.5rem',
            marginBottom: '1rem',
          }}
        >
          <FaInfoCircle color={'#22a7f0'} />
          <span style={{ color: '#696f8c', fontSize: '0.875rem' }}>
            Use shortcut: type “[“ to open menu with variables
          </span>
        </div>
        {!isSmsMessage && (
          <>
            <InputToggle
              $variant={'primary'}
              $size={'small'}
              $labelRight={'Use Coupons'}
              $isChecked={initialValues.isCouponActive}
              $isDisabled={isBridgeEnabled}
              onChange={() => {
                const newState = !initialValues.isCouponActive
                couponActiveHelpers.setValue(newState)
              }}
            />

            {initialValues.isCouponActive && (
              <div style={{ marginTop: '1rem' }}>
                <InputSmartCreatableSelect
                  key={JSON.stringify(initialValues.coupons || [])}
                  defaultValue={initialValues.coupons}
                  initialValues={allAvailableCoupons}
                  onPaste={managePastedData}
                  onSelect={handleSelectCoupon}
                  isDisabled={isBridgeEnabled}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
