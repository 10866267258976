import React, { Suspense } from 'react'
import { Loader } from '@components/Basic/Loader'

const CheckoutLazy = React.lazy(() => import('./CheckoutPageComponent'))

export const CheckoutPage = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: 'relative',
            height: 200,
          }}
        >
          <Loader $active $dimmer $size={'large'} />
        </div>
      }
    >
      <CheckoutLazy />
    </Suspense>
  )
}
