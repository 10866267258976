import React, { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { sendToast } from '../../store/actions/ApplicationConfigurationActions'
import { Loader } from '@components/Basic/Loader'
import { useAppDispatch } from '@app/store/hooks'
import { clearPersistentData } from '@app/pages/Logout/clearPersistentData'
import { forgetUser } from '@app/dataTracking'
import { useTranslation } from 'react-i18next'

const Logout: React.FC = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const search = (window.location.search || '').replace('??', '?') // remove double ?? generate by v1
  const qs = queryString.parse(search)
  const { t } = useTranslation()

  // https://leadsbridge.atlassian.net/browse/SB-572
  // In order to logout the user also from V1 we need to load the v1/app/logout url inside an iframe.
  // Strategy is to inject an iframe, prevent infinite injections  by checking the presence of `preventRedirect` in query string
  // When this code/component is loaded inside the iframe we detect `preventRedirect` and send a post message to paret
  // Parent (this same component) receives the message and performs a logout in cognito and redirect user to `path` where they was or to `/`
  const isInIframe = qs.preventRedirect || qs.path?.includes('preventRedirect')

  // Create the iframe to load v1 logout
  useEffect(() => {
    if (isInIframe) {
      console.log('We are in iframe')
      window.postMessage({ hasV1LoggedOut: true }, '*')
      return
    }

    if (document.getElementById('logout-v1-iframe')) {
      // we don't want each re-rendering to create new iframes
      return
    }

    // we need to be sure that the iframe url contains preventRedirect so V1 will keep it during redirection
    // and we won't create infinite loop of iframes
    const logoutV1Url = `${process.env.REACT_APP_V1_URL}/logout?preventRedirect=1`
    const iframe = document.createElement('iframe')
    iframe.src = logoutV1Url
    iframe.id = 'logout-v1-iframe'
    iframe.style.position = 'absolute'
    iframe.style.top = '-1000px'
    iframe.style.opacity = '0'
    document.body.appendChild(iframe)
  }, [])

  // Generic function to logout the user
  const logoutFromCognitoAndRedirectUser = () => {
    if (isInIframe) {
      return // parent will be in charge, no need to do this in iframe
    }
    // remove cookies and localStorage
    clearPersistentData().then(() => {
      forgetUser() //  flush segment user info
      Auth.signOut().then(() => {
        const postLogoutUrl = `${qs.path || '/'}`
        console.log('Logout from Cognito -> Redirect user to -> ', postLogoutUrl)
        history.push(postLogoutUrl)
      })
    })
  }

  // We are expecting to receive data as postMessage from parent window
  // Once we have received the message we can proceed with the v2 logout
  const messageReceiverFn = function (event: MessageEvent) {
    if (event.data.hasV1LoggedOut) {
      console.log('Received post message from logout iframe')
      logoutFromCognitoAndRedirectUser()
    }
  }
  useEffect(() => {
    window.addEventListener('message', messageReceiverFn)
    return () => window.removeEventListener('message', messageReceiverFn)
  }, [])
  // in case something goes wrong, we proceed with the logout after few seconds
  useEffect(() => {
    setTimeout(() => {
      console.log('logout timeout')
      logoutFromCognitoAndRedirectUser()
    }, 6000)
  }, [])

  // display toast to notify something to user (eg. session is expired)
  useEffect(() => {
    if (qs && qs.message) {
      try {
        const encodedStringAtoB = window.atob(qs.message.toString())
        const JSONParsed = JSON.parse(encodedStringAtoB)
        const alertTitle = JSONParsed.title ?? ''
        const alertMessage = t('auth.logout.toastMessage') ?? ''
        const alertColor = JSONParsed.type ?? 'negative'
        dispatch(
          sendToast({
            title: alertTitle,
            message: alertMessage,
            color: alertColor,
          })
        )
      } catch (e) {}
    }
  }, [])

  return (
    <div>
      <Loader $active />
    </div>
  )
}

export default Logout
