import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { IntegrationAuthScreenEdit } from './IntegrationAuthScreenEdit'
import { IntegrationAuthScreen } from './IntegrationAuthScreen'
import { IntegrationHome } from '@app/pages/Integration/IntegrationHome'
import { IntegrationAuthCallback } from './IntegrationAuthCallback'
import { IntegrationAuthCallback2 } from './IntegrationAuthCallback2'

export const IntegrationRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.integrationAuthScreenCallback2.path}
        component={IntegrationAuthCallback2}
      />
      <Route
        exact
        path={appRoutes.integrationAuthScreenCallback.path}
        component={IntegrationAuthCallback}
      />
      <Route
        path={[
          appRoutes.integrationAuthScreenEdit.path,
          appRoutes.integrationAuthScreenEditWithBridgeId.path,
          appRoutes.integrationAuthScreenEditStep2.path,
        ]}
        exact
        component={IntegrationAuthScreenEdit}
      />
      <Route
        path={appRoutes.integrationAuthScreen.path}
        exact
        component={IntegrationAuthScreen}
      />
      <Route component={IntegrationHome} />
    </Switch>
  )
}
