import { StatusBar } from '@components/StatusBar'
import React, { useEffect, useState } from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDisableBridgeById } from '@app/api/disableBridge'
import { parseApiError } from '@app/api/utils/error'
import { BridgeFormValues } from 'Nbee'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { trackEvent } from '@app/dataTracking'
import { makeNbeeTrackingParams } from '@app/dataTracking/utils'

interface StatusBarBridgeProps {
  bridgeId: number
  isVisible?: boolean
}
export const StatusBarBridge: React.FC<StatusBarBridgeProps> = ({
  bridgeId,
  isVisible,
}) => {
  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()
  const isBridgeEnabled = values.ui?.isBridgeEnabled

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    mutate: disableBridge,
    isLoading: isDisablingBridge,
    error: disableBridgeApiError,
    data: disableBridgeResponse,
  } = useDisableBridgeById()

  // in case of success we update the form values setting the bridge as disabled
  useEffect(() => {
    if (disableBridgeResponse) {
      const newStatus = !!disableBridgeResponse?.data?.active
      setFieldValue('ui.isBridgeEnabled', newStatus)
    }
  }, [disableBridgeResponse])

  useEffect(() => {
    if (disableBridgeApiError) {
      const parsedError = parseApiError(disableBridgeApiError)
      dispatch(
        sendToast({
          title: 'Error',
          message: parsedError.message,
          color: 'negative',
        })
      )
      trackEvent({
        eventName: 'UnexpectedErrorThrown',
        feature: 'NBEE',
        step: 'Apps',
        params: {
          ...makeNbeeTrackingParams(values),
          custom: {
            errorCode: parsedError.code,
            errorDescription: parsedError.message,
          },
        },
        sendEventToIntercom: true,
      })
    }
  }, [disableBridgeApiError])

  return isBridgeEnabled ? (
    // enabled bridge can't be edited, user needs to disable it first
    <StatusBar
      onClick={() => {
        disableBridge({ bridgeId })
      }}
      buttonLabel={t('nbee.bridgeEnded.enableEditCta')}
      text={t('nbee.bridgeEnded.ViewDescription')}
      status={t('nbee.bridgeEnded.ViewMode')}
      isLoading={isDisablingBridge}
      allowManualClosing={false}
    />
  ) : bridgeId && isVisible ? (
    // Here bridge is not enabled and we tell the user that it needs to go all the way throught to publish it
    // This can only be shown in first step, because in step 2 we always have a bridgeId (when bridge is just been created)
    <StatusBar
      text={t('nbee.bridgeEnded.editingDescription')}
      status={t('nbee.bridgeEnded.editingMode')}
      allowManualClosing={false}
    />
  ) : null
}
