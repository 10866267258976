import React from 'react'
import { FakeInputStyled } from './styled'

export interface FakeInputProps extends React.HTMLAttributes<HTMLDivElement> {
  required?: boolean
  text?: string
}

export const FakeInput: React.FC<FakeInputProps> = ({
  required,
  text,
  ...props
}) => {
  return (
    <FakeInputStyled required={required} {...props}>
      <p>{text}</p>
    </FakeInputStyled>
  )
}
