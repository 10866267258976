import React from 'react'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { useField, useFormikContext } from 'formik'
import { Label } from '@components/Form/Label'
import { InputFeedback } from '@components/Basic/InputFeedback'
import { FormikInputSmartSelectStyled } from './styled'

export interface Props {
  initialValues: SelectValue[]
  name: string
  label?: string
  isLoading?: boolean
  secondaryStyles?: boolean
  noIcon?: boolean
  isClearable?: boolean
}
export const FormikInputSmartSelect: React.FC<Props> = ({
  initialValues,
  name,
  label,
  isLoading,
  secondaryStyles,
  noIcon,
  isClearable,
}) => {
  const [field, meta, helpers] = useField(name)
  const errorMessage = meta.touched && meta.error
  const defaultValue = initialValues.find((item) => item.value === field.value)

  return (
    <FormikInputSmartSelectStyled secondaryStyles={secondaryStyles}>
      <Label $label={label} />
      <InputSmartSelect
        onBlur={() => {
          helpers.setTouched(true)
        }}
        isLoading={isLoading}
        key={defaultValue?.value}
        defaultValue={defaultValue}
        initialValues={initialValues || []}
        onSelect={(selectedValue) => {
          const currentValue = selectedValue as SelectValue | null
          helpers.setValue(currentValue?.value || '')
        }}
        secondaryStyles={secondaryStyles}
        noIcon={noIcon}
        isClearable={isClearable}
      />
      {errorMessage ? (
        <InputFeedback $status={{ error: errorMessage }} />
      ) : null}
    </FormikInputSmartSelectStyled>
  )
}
