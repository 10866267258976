import React, { useEffect, useRef, useState } from 'react'

import {
  PanelPopupBody,
  PanelPopupButtonClose,
  PanelPopupContent,
  PanelPopupHeader,
  PanelPopupImageWrapper,
  PanelPopupStyled,
  PanelPopupWrapper,
} from './styled'
import { useTranslation } from 'react-i18next'
import { IoCloseOutline } from 'react-icons/io5'

export interface PanelPopupProps {
  image?: string
  dismissable?: boolean
  onClose?: () => void
  content?: React.ReactNode
  position?: 'floating' | 'bottom'
}

export const PanelPopup: React.VFC<PanelPopupProps> = ({
  image,
  content,
  onClose,
  dismissable,
  position,
}) => {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState(false)
  const timeoutId = useRef<NodeJS.Timeout>()

  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      setIsVisible(true)
    }, 1)

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
      }
    }
  }, [])

  return (
    <PanelPopupStyled
      position={position || 'floating'}
      className={isVisible ? 'visible' : ''}
    >
      <PanelPopupWrapper>
        <PanelPopupHeader>
          {dismissable ? (
            <PanelPopupButtonClose
              onClick={() => {
                if (onClose) {
                  onClose()
                }
              }}
            >
              <IoCloseOutline /> {t('common.close')}
            </PanelPopupButtonClose>
          ) : null}
        </PanelPopupHeader>

        <PanelPopupBody>
          <PanelPopupContent>{content}</PanelPopupContent>
          {image ? (
            <PanelPopupImageWrapper>
              <img src={image} />
            </PanelPopupImageWrapper>
          ) : null}
        </PanelPopupBody>
      </PanelPopupWrapper>
    </PanelPopupStyled>
  )
}
