import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface LayoutProps {
  leftSlot?: ReactNode
  hideLeftOnMobile?: boolean
  rightSlot: ReactNode
}

const SingupLayoutWrapper = styled.div`
  background-color: #41a6dc;
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  @media (min-width: 980px) {
    padding: 0 1rem;
  }
`

const SignupLayoutStyled = styled.div`
  width: 100%;
  min-height: 100vh;

  max-width: ${({ theme }) => theme.container.extralarge};
  display: grid;
  align-items: center;
  margin: 0 auto;
  gap: 1rem;

  @media (min-width: 980px) {
    grid-template-columns: 1fr 1fr;
  }
`

const ContainerStyled = styled.div<{ $hideOnMobile?: boolean }>`
  display: flex;
  justify-content: center;

  /* in generic singup left image needs to disappear on mobile, in bbu not  */
  ${({ $hideOnMobile }) =>
    $hideOnMobile
      ? css`
          @media (max-width: 768px) {
            display: none;
          }
        `
      : null}
`

export const SignupLayout: React.FC<LayoutProps> = ({
  leftSlot,
  rightSlot,
  hideLeftOnMobile,
}) => {
  return (
    <SingupLayoutWrapper>
      <SignupLayoutStyled>
        <ContainerStyled $hideOnMobile={hideLeftOnMobile}>
          {leftSlot}
        </ContainerStyled>
        <ContainerStyled>{rightSlot}</ContainerStyled>
      </SignupLayoutStyled>
    </SingupLayoutWrapper>
  )
}
