import React from 'react'
import { StateIconStyled } from '@components/Basic/StateIcon/styled'
import { IoIosCheckmarkCircleOutline } from 'react-icons/io'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { AiOutlineMinusCircle } from 'react-icons/ai'

export type ValidationStates = 'empty' | 'default' | 'success' | 'error'

interface StateIconProps {
  state?: ValidationStates
  iconSize?: string
}

export const StateIcon: React.FC<StateIconProps> = ({
  state = 'empty',
  iconSize = '24',
}) => {
  // set of allowed icons
  const icons: Record<ValidationStates, JSX.Element> = {
    default: <AiOutlineMinusCircle color={'#ABB4BD'} size={iconSize} />,
    success: <IoIosCheckmarkCircleOutline color={'#2ECD72'} size={iconSize} />,
    error: <IoAlertCircleOutline color={'#EF4836'} size={iconSize} />,
    // return an empty svg to keep height consistent with other
    empty: <svg width={2} height={iconSize} />,
  }

  return (
    <StateIconStyled>
      <span />
      {icons[state]}
      <span />
    </StateIconStyled>
  )
}
