import React, { useState, useEffect, Fragment } from 'react'
import { useField, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  BridgeFormValues,
  MappedField,
  MappedFieldFormula,
  MappedFieldMapping,
} from 'Nbee'

import { Button } from '@components/Basic/ButtonNbe'
import { Loader } from '@components/Basic/Loader'
import { Modal } from '@components/Basic/Modal'
import { InputField } from '@app/components/Form/InputField'
import {
  InputSmartSelect,
  SelectValue,
} from '@components/Form/InputSmartSelect'
import { InputSmartCreatableSelect } from '@app/components/Form/InputSmartSelect/Creatable'

import {
  FormulaModalBody,
  FormulaModalCloseIcon,
  FormulaModalFooter,
  FormulaModalHeader,
  FormulaModalHero,
  FormulaModalWrapper,
} from '@features/nbee/FieldsMappingForm/FormulaModal/styled'
import { IoCloseOutline } from 'react-icons/io5'

import { useGetSingleFormulaSchema } from '@app/api/getSingleFormulaSchema'

interface ModalProps {
  selectedMapping?: MappedFieldMapping
  isOpen: boolean
  onCloseModal: (formula: MappedFieldFormula) => void
  sourceFieldOptions?: SelectValue[] | []
  index: number
}

export const FormulaModal: React.FC<ModalProps> = ({
  selectedMapping,
  isOpen,
  onCloseModal,
  sourceFieldOptions,
  index,
}) => {
  const mappingId = selectedMapping?.id
  const formulaId = selectedMapping?.formula?.id
  const isNew = selectedMapping?.formula?.isNew
  const modalTitle = isNew ? 'Add' : 'Edit'

  const { t } = useTranslation()

  // Extract information from Formik context
  const { values } = useFormikContext<BridgeFormValues>()

  const fieldName = `fieldsMapping.${index}`
  const [field, meta, helpers] = useField<MappedField>(fieldName)

  const currentFieldValue = field.value
  const currentFieldMappingWithFormula = field.value.mapping.find(
    (m) => m.formula && m.id === mappingId // Matches the mapping ID
  )

  // Now you can access the formula and its params directly
  const currentFieldFormula = currentFieldMappingWithFormula?.formula
  const currentFieldFormulaParams = currentFieldFormula?.params

  // Create a temporary state to not save immediately in Formik context
  const [formulaTemporaryState, setFormulaTemporaryState] =
    useState<MappedFieldFormula>(currentFieldFormula as MappedFieldFormula)

  // Disable submit button if there are errors in the form
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
    useState<boolean>(false)

  // Retrieve single formula schema, passing bridge ID and formula ID
  const { data: singleFormulaSchema, isLoading: isGettingSingleFormulaSchema } =
    useGetSingleFormulaSchema(`${values.ui?.bridgeId}`, formulaId)

  // Memoize formula name, formula description and param title to avoid re-rendering
  const formulaName = React.useMemo(
    () =>
      singleFormulaSchema && singleFormulaSchema.data.length
        ? `${singleFormulaSchema?.data[0]?.category} >> ${t(
            `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Name` as any
          )}`
        : undefined,
    [t, singleFormulaSchema]
  )
  const formulaDescription = React.useMemo(
    () =>
      singleFormulaSchema
        ? t(
            `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Description` as any
          )
        : undefined,
    [t, singleFormulaSchema]
  )
  const getParamDescription = React.useMemo(
    () => (paramId: string) =>
      t(
        `nbee.formulas.${singleFormulaSchema?.data[0]?.id}.Params.${paramId}` as any
      ),
    [t, singleFormulaSchema]
  )

  const singleFormulaSchemaParams = singleFormulaSchema?.data[0]?.params || []

  // if there are no params in the formik context it's because we've just changed the formula.
  // in that case, we need to load in the state the params of the new formula
  useEffect(() => {
    if (singleFormulaSchema && !currentFieldFormulaParams?.length) {
      setFormulaTemporaryState({
        ...(currentFieldFormula as MappedFieldFormula),
        params:
          singleFormulaSchema?.data[0]?.params?.map((p, index) => {
            return {
              ...p,
              required: p.type === 'field' && index === 0,
            }
          }) || [],
      })
    } else if (singleFormulaSchema && currentFieldFormulaParams?.length) {
      // otherwise, we need to update the state with the params of the formik context
      setFormulaTemporaryState({
        ...(currentFieldFormula as MappedFieldFormula),
        params:
          currentFieldFormulaParams?.map((p) => ({
            ...p,
            values: p.values,
            required: p.type === 'field' && index === 0,
          })) || [],
      })
    }
  }, [singleFormulaSchema])

  const handleValueChange = (
    paramId: string,
    value: string | string[] | number
  ) => {
    if (!formulaTemporaryState) {
      return undefined
    }

    const updatedFormula: MappedFieldFormula = {
      id: formulaTemporaryState.id,
      params: [...formulaTemporaryState?.params] || [],
    }
    const paramIndex = updatedFormula.params.findIndex((p) => p.id === paramId)
    if (paramIndex >= 0) {
      updatedFormula.params[paramIndex].values = value as string | string[]
    }

    // Handle field value change and update state, not formik context
    setFormulaTemporaryState(updatedFormula)
  }

  const getOptionsForSelectDropdown = (paramId: string) => {
    const selectedParam = singleFormulaSchema?.data[0]?.params?.find(
      (p) => p.id === paramId
    )
    if (Array.isArray(selectedParam?.values) && selectedParam?.values) {
      return selectedParam?.values.map(
        (v) => ({ value: v, label: v } as SelectValue)
      )
    } else {
      return []
    }
  }

  const getDefaultValueForSelect = (paramId: string) => {
    if (formulaTemporaryState?.isNew) return undefined
    if (!formulaTemporaryState?.params || !sourceFieldOptions) return undefined

    const selectedParam = formulaTemporaryState?.params.find(
      (p) => p.id === paramId
    )
    if (!selectedParam) {
      return undefined
    }
    if (selectedParam.type === 'field') {
      return sourceFieldOptions.find(
        (option) => option.value === selectedParam.values
      )
    }
    if (selectedParam.type === 'dropdown') {
      const dropdownOptions = getOptionsForSelectDropdown(paramId)
      let defaultValue = dropdownOptions?.find(
        (option) => option.value === selectedParam.values
      )
      if (defaultValue === undefined && dropdownOptions.length > 0) {
        defaultValue = {
          value: dropdownOptions[0].value,
          label: dropdownOptions[0].value,
        } as SelectValue
        handleValueChange(paramId, dropdownOptions[0].value)
      }

      return defaultValue
    }
  }

  const getDefaultValueForCreatableSelect = (paramId: string) => {
    if (!formulaTemporaryState?.params?.length) {
      return undefined
    }
    const selectedParam = formulaTemporaryState?.params.find(
      (p) => p.id === paramId
    )
    return (selectedParam?.values as string[]) || []
  }

  const transformFieldId = (paramId?: string): string | null => {
    if (!paramId) return null

    return paramId
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/field\.(\d+)/gi, (_, num) => `Field ${Number(num) + 1}`)
      .replace(/\b(\w)/g, (char) => char.toUpperCase())
  }

  // Handle submit button disabled state
  useEffect(() => {
    if (isGettingSingleFormulaSchema) {
      setIsSubmitButtonDisabled(true)
    }
    if (!formulaTemporaryState?.params[0]?.values) {
      setIsSubmitButtonDisabled(true)
    } else {
      setIsSubmitButtonDisabled(false)
    }
  }, [formulaTemporaryState])

  const handleAbort = () => {
    // Find the index of the mapping formula to reset
    const mappingIndex = currentFieldValue.mapping.findIndex(
      (m) => m.id === mappingId
    )

    if (mappingIndex !== -1 && isNew) {
      // Remove the mapping formula from the array only when it's a new formula
      const newMappings = currentFieldValue.mapping.filter(
        (m, i) => i !== mappingIndex
      )

      // Update the MappedField with the modified mappings
      helpers.setValue({
        ...currentFieldValue,
        mapping: newMappings,
      })
    }
    // if we're editing an existing formula, don't do anything

    onCloseModal(currentFieldFormula as MappedFieldFormula)
  }

  const handleSubmit = () => {
    // Create a new mapping array by mapping over the currentFieldValue.mapping
    const updatedMapping = currentFieldValue.mapping.map((mapping) => {
      // Check if the current mapping is the one being edited (matched by mappingId)
      if (mapping.id === mappingId) {
        // If it is, return a new mapping object with the updated formulaTemporaryState
        return {
          ...mapping,
          formula: { ...formulaTemporaryState, isNew: false },
        }
      }
      // For all other mappings, return them unchanged
      return mapping
    })

    // Set the updated mapping array back into the Formik state
    helpers.setValue({
      ...currentFieldValue,
      mapping: updatedMapping,
    })

    // Close the modal and pass the updated formula back as a confirmation (if needed)
    onCloseModal(formulaTemporaryState)
  }

  return (
    <Modal
      noPadding
      isOpen={isOpen}
      onCloseModal={() =>
        onCloseModal(currentFieldFormula as MappedFieldFormula)
      }
    >
      {isGettingSingleFormulaSchema && (
        <Loader $active $dimmer $text='Populating fields...' />
      )}

      <FormulaModalWrapper>
        <FormulaModalHeader>
          <div>
            <h3>
              {modalTitle} {(formulaName && formulaName.toUpperCase()) || ''}
            </h3>
            <FormulaModalHero>
              <small>{formulaDescription}</small>
            </FormulaModalHero>
          </div>
          <FormulaModalCloseIcon onClick={handleAbort}>
            <IoCloseOutline />
          </FormulaModalCloseIcon>
        </FormulaModalHeader>

        <FormulaModalBody>
          {singleFormulaSchemaParams &&
            singleFormulaSchemaParams.map((param) => (
              <Fragment key={`${param.id}-${isOpen ? 'open' : 'closed'}`}>
                <div style={{ marginTop: '.6rem' }}>
                  <h5>{transformFieldId(param?.id)}</h5>
                  <small
                    style={{
                      display: 'inline-block',
                      maxWidth: '40rem',
                      wordWrap: 'break-word',
                    }}
                  >
                    {getParamDescription(param.id)}
                  </small>
                </div>
                <div style={{ margin: '.6rem 0' }}>
                  {formulaTemporaryState ? (
                    param.type === 'field' ? (
                      <InputSmartSelect
                        initialValues={sourceFieldOptions || []}
                        defaultValue={getDefaultValueForSelect(param.id)}
                        onSelect={(selectedValue) => {
                          const singleValue = selectedValue as SelectValue
                          if (singleValue) {
                            handleValueChange(param.id, singleValue.value)
                          }
                        }}
                        upDownIconsStyle
                        isClearable
                      />
                    ) : param.type === 'dropdown' ? (
                      <InputSmartSelect
                        initialValues={getOptionsForSelectDropdown(param.id)}
                        defaultValue={getDefaultValueForSelect(param.id)}
                        onSelect={(selectedValue) => {
                          const singleValue = selectedValue as SelectValue
                          if (singleValue) {
                            handleValueChange(param.id, singleValue.value)
                          }
                        }}
                        upDownIconsStyle
                        isClearable
                      />
                    ) : param.type === 'text' ||
                      param.type === 'char' ||
                      param.type === 'int' ? (
                      <InputField
                        name={`${param.id}`}
                        type={'text'}
                        maxLength={param.type === 'char' ? 1 : undefined}
                        value={
                          (formulaTemporaryState &&
                            formulaTemporaryState?.params.find(
                              (p) => p.id === param.id
                            )?.values) ||
                          ''
                        }
                        onChange={(e) =>
                          handleValueChange(param.id, e.target.value)
                        }
                      />
                    ) : param.type === 'tags' ? (
                      <InputSmartCreatableSelect
                        name={`${param.id}`}
                        placeholder={''}
                        defaultValue={getDefaultValueForCreatableSelect(
                          param.id
                        )}
                        onSelect={(newValues) =>
                          handleValueChange(param.id, newValues)
                        }
                        isClearable={false}
                      />
                    ) : null
                  ) : null}
                </div>
              </Fragment>
            ))}
        </FormulaModalBody>

        <FormulaModalFooter>
          <Button type={'button'} $variant={'secondary'} onClick={handleAbort}>
            {'Abort'}
          </Button>
          <Button
            type={'button'}
            $variant={'primary'}
            onClick={handleSubmit}
            disabled={isSubmitButtonDisabled}
          >
            {'Confirm'}
          </Button>
        </FormulaModalFooter>
      </FormulaModalWrapper>
    </Modal>
  )
}
