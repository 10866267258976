import React from 'react'
import { Modal } from '@components/Basic/Modal'
import { Content, ButtonWrapper } from '../ModalChangePlan/styled'
import wellDoneImg from '@assets/images/well_done.png'
import { Button } from '@components/Basic/ButtonNbe'
import { useTranslation } from 'react-i18next'

interface ModalProps {
  isOpen: boolean
}

export const ModalUpdatedPlan: React.FC<ModalProps> = ({ isOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      onCloseModal={() => {
        window.location.href = process.env.REACT_APP_V1_URL || ''
      }}
      isWide
    >
      <Content>
        <h2 style={{ fontSize: '1.3rem', fontWeight: 800 }}>
          {t('billing.pricing.modalDowngradeTitleSuccess')}
        </h2>
        <hr />
        <p>{t('billing.pricing.modalDowngradeTextSuccess')}</p>
        <img
          style={{ marginBottom: '1rem' }}
          src={wellDoneImg}
          alt={'super hero'}
        />
        <ButtonWrapper>
          <Button
            $size={'large'}
            $variant={'primary'}
            onClick={() => {
              window.location.href = process.env.REACT_APP_V1_URL || ''
            }}
          >
            {t('billing.pricing.modalDowngradeCtaSuccess')}
          </Button>
        </ButtonWrapper>
      </Content>
    </Modal>
  )
}
