import React from 'react'
import {
  FeaturesStyled,
  PlanDescription,
  PlanHeaderLeft,
  PlanHeaderRight,
  PlanName,
  PlanType,
  PriceDescription,
  PriceDiscount,
  PriceStyled,
  PricingCardBox,
  PricingCardHeaderStyled,
  PricingCardTopBarLabel,
  PricingCardTopBarWrapper,
  PricingCardWrapper,
} from '@features/pricing/PricingCard/styled'

import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { ApiMainFeatures, ApiUser } from 'BackendApi'

export interface PricingCardProps {
  planName: string
  planDescription: string
  price: number
  priceDescription: string
  features: ApiMainFeatures[]
  isYearlyChecked: boolean
  yearlyDiscountPercentage?: string
  isActive?: boolean
  isRecommended?: boolean
  isCurrentPlan?: boolean
  showUndo?: boolean
  remainDays?: number
  onUndoSelect: (planId: string | undefined) => void
  userData: ApiUser
  endOfTermsDate?: string
  deleteAtDate?: string
}

export const planNameToShow: Record<string, string> = {
  freemium: 'Free',
  starter: 'Starter',
  pro: 'Pro',
  business: 'Business',
}

export const getPlanNameToShow = (planName: string) => {
  return planNameToShow[planName] || planNameToShow
}

export const PricingCard: React.FC<PricingCardProps> = ({
  features,
  planName,
  planDescription,
  price,
  priceDescription,
  isActive,
  isRecommended,
  isCurrentPlan,
  isYearlyChecked,
  yearlyDiscountPercentage,
  children,
  remainDays,
  onUndoSelect,
  userData,
  endOfTermsDate,
  deleteAtDate,
}) => {
  const { t } = useTranslation()

  const showUndoPro =
    (endOfTermsDate || deleteAtDate) &&
    (planName === 'PRO' || planName === 'STARTER') &&
    isCurrentPlan

  const undoMessage = deleteAtDate
    ? 'billing.pricing.cancellingCardHeader'
    : endOfTermsDate
    ? 'billing.pricing.switchingToFreeCardHeader'
    : 'billing.pricing.switchingToFreeCardHeader' // Providing a default value to ensure non-nullability for TypeScript

  const planId = userData?.pricing?.cbPlanId

  return (
    <PricingCardWrapper planName={planName.toLocaleLowerCase()}>
      {showUndoPro ? (
        <PricingCardTopBarWrapper>
          <PricingCardTopBarLabel showUndoPro={showUndoPro}>
            <p>
              {t(undoMessage, { remainDays })}
              <span onClick={() => onUndoSelect(planId)}>Undo</span>
            </p>
          </PricingCardTopBarLabel>
        </PricingCardTopBarWrapper>
      ) : isRecommended || isCurrentPlan ? (
        <PricingCardTopBarWrapper>
          <PricingCardTopBarLabel isCurrentPlan={isCurrentPlan}>
            {isCurrentPlan
              ? t('billing.pricing.pricingColumnCurrent')
              : isRecommended
              ? t('billing.pricing.pricingColumnRecommended')
              : null}
          </PricingCardTopBarLabel>
        </PricingCardTopBarWrapper>
      ) : null}

      <PricingCardBox isActive={isActive} isRecommended={isRecommended}>
        <PlanType>
          {planName.toLowerCase() !== 'business'
            ? 'self-service'
            : 'managed service'}
        </PlanType>

        <PricingCardHeaderStyled>
          <PlanHeaderLeft>
            <PlanName planName={planName.toLocaleLowerCase()}>
              {getPlanNameToShow(planName.toLowerCase())}
            </PlanName>
            <PlanDescription planName={planName.toLocaleLowerCase()}>
              {planDescription}
            </PlanDescription>
          </PlanHeaderLeft>
          <PlanHeaderRight>
            <PriceStyled>
              <span>$</span>
              <h2>
                {isYearlyChecked &&
                yearlyDiscountPercentage &&
                planName.toLocaleLowerCase() !== 'business' &&
                planName.toLocaleLowerCase() !== 'freemium' ? (
                  <PriceDiscount isYearlyChecked={isYearlyChecked}>
                    {''}- {yearlyDiscountPercentage}%{''}
                  </PriceDiscount>
                ) : null}

                {price}
              </h2>
            </PriceStyled>
            <PriceDescription>{priceDescription}</PriceDescription>
          </PlanHeaderRight>
        </PricingCardHeaderStyled>
        <div style={{ margin: '1.5rem 0 1rem' }}>{children}</div>
        <FeaturesStyled>
          {features.map((feature, index) =>
            // Clear map result: avoid dot without feature.name label
            feature.name ? (
              <li key={index}>
                {feature.description ? (
                  <>
                    <ReactTooltip
                      className={'feature-item-styled'}
                      clickable={true}
                      place='top'
                      effect='solid'
                      html={true}
                      id={`${planName}_feature_${index}`}
                    />
                    <span
                      className={'feature-item-label-styled'}
                      style={{ cursor: 'help' }}
                      data-tip={feature.description}
                      data-for={`${planName}_feature_${index}`}
                    >
                      {feature.name}
                    </span>
                  </>
                ) : (
                  <>{feature.name}</>
                )}
              </li>
            ) : null
          )}
        </FeaturesStyled>
      </PricingCardBox>
    </PricingCardWrapper>
  )
}
