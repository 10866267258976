import React, { ButtonHTMLAttributes } from 'react'
import { ButtonStyled } from './styled'
import { FaFacebook, FaTiktok } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'

type ButtonStyle =
  | 'facebook'
  | 'google'
  | 'tiktok'
  | 'primary'
  | 'secondary'
  | 'link'

export type ButtonSize = 'inline-small' | 'large'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $fluid?: boolean
  $loading?: boolean
  $variant?: ButtonStyle
  $size?: ButtonSize
  disabled?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  $variant,
  children,
  ...props
}) => {
  let icon
  switch ($variant) {
    case 'google':
      // we need to compensate some icon disaligment
      icon = <FcGoogle style={{ marginLeft: '-2px' }} />
      break
    case 'facebook':
      icon = <FaFacebook />
      break
    case 'tiktok':
      icon = <FaTiktok />
      break
    default:
      icon = null
  }

  return (
    <ButtonStyled $variant={$variant} {...props} disabled={props.disabled}>
      {icon}
      {children}
    </ButtonStyled>
  )
}
