import React, { forwardRef, InputHTMLAttributes } from 'react'
import { TextareaWrapper, TextareaStyled } from './styled'
import { Label } from '@components/Form/Label'
import {
  InputFeedbackStatus,
  InputFeedback,
} from '@components/Basic/InputFeedback'

export interface InputFieldAttrProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  $status?: InputFeedbackStatus
  name: string
  label?: string
}

export const Textarea = forwardRef<HTMLTextAreaElement, InputFieldAttrProps>(
  ({ label, $status, ...props }, ref) => {
    const inputId = `${props.name}`

    return (
      <TextareaWrapper>
        {label && (
          <Label $disabled={props.disabled} htmlFor={inputId} $label={label} />
        )}
        <TextareaStyled ref={ref} $status={$status} {...props} />
        {$status && <InputFeedback $status={$status} />}
      </TextareaWrapper>
    )
  }
)

Textarea.displayName = 'Textarea'
