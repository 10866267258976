import React from 'react'
import { StepBarItem } from '@components/StepBar/StepBarItem'
import { StepBarContainer } from './styled'

export type StepBarStep = {
  onStepClick?: (stepIndex: number) => void
  label?: string
  title?: string
  subLabel?: string
}

export interface StepBarProps {
  steps: StepBarStep[]
  activeStep: number
}

export const StepBar: React.FC<StepBarProps> = ({ steps, activeStep }) => {
  return (
    <StepBarContainer>
      {steps.map((step, index) => {
        return (
          <StepBarItem
            key={index}
            index={index}
            isActive={activeStep === index}
            title={step.title}
            label={step.label}
            subLabel={step.subLabel}
            onStepClick={step.onStepClick}
          />
        )
      })}
    </StepBarContainer>
  )
}
