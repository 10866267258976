import React from 'react'
import { Checkbox } from '@components/Form/Checkbox'
import { Wrapper, FieldsRow, InputBox, ValueBox, DataTable } from './styled'
import { ApiIntegrationListenerField } from '@app/@typings/Api/integration'

interface Props {
  fields: ApiIntegrationListenerField[]
  onFieldChange: (fieldId: string, enabled: boolean) => void
}

export const IncomingDataTable: React.FC<Props> = ({
  fields,
  onFieldChange,
}) => {
  const handleCheckboxChange = (item: ApiIntegrationListenerField) => {
    const enabled = !item.enabled
    onFieldChange(item.id, enabled)
  }
  return (
    <Wrapper data-testid={'table'}>
      <DataTable>
        {fields.map((field) => (
          <FieldsRow key={field.id}>
            <InputBox>
              <Checkbox
                aria-label={field.label}
                checked={field.enabled}
                name={`checkbox-${field.label}`}
                onChange={() => handleCheckboxChange(field)}
              />
            </InputBox>
            <ValueBox style={{ fontWeight: 600 }}>{field.label}</ValueBox>
            <ValueBox>{field.lastValue}</ValueBox>
          </FieldsRow>
        ))}
      </DataTable>
    </Wrapper>
  )
}
