import React, { useEffect, useRef, useState } from 'react'
import {
  AlertBody,
  AlertBodyContent,
  AlertButtonClose,
  AlertFooter,
  AlertHeader,
  AlertImageWrapper,
  AlertStyled,
  AlertWrapper,
} from './styled'
import { useSelector } from 'react-redux'
import { RootState } from '@app/index'
import { Alert } from 'LeadsBridgeApp'
import { IoCloseOutline } from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import { AlertProgressBar } from '@components/Alert/AlertProgressBar/AlertProgressBar'
import { Translate } from '@components/Translate'
import { Container } from '../Basic/Container'

export const AlertComponent: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const alert: Alert | null = useSelector(
    (state: RootState) => state.application.alert
  )
  const { t } = useTranslation()

  useEffect(() => {
    if (alert) {
      setVisible(true)
    }
  }, [alert])

  const timerId = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (alert && alert.dismissableTimer) {
      timerId.current = setTimeout(() => {
        setVisible(false)
      }, alert.dismissableTimer)
    }

    return () => {
      if (timerId.current) {
        clearTimeout(timerId.current)
      }
    }
  }, [alert?.dismissableTimer])

  if (!alert) return null

  return (
    <AlertStyled isVisible={visible}>
      <Container $size={'lg'}>
        <AlertWrapper>
          <AlertHeader>
            {alert.isDismissable ? (
              <AlertButtonClose
                onClick={() => {
                  setVisible(false)
                  if (alert?.onClose) {
                    alert.onClose()
                  }
                }}
              >
                <IoCloseOutline /> {t('common.close')}
              </AlertButtonClose>
            ) : null}
          </AlertHeader>

          <AlertBody>
            <AlertBodyContent>
              {alert.title ? <h4>{alert.title}</h4> : null}
              <p
                style={{
                  maxWidth: 600,
                }}
              >
                {alert.useTranslation ? (
                  <Translate
                    i18nKey={alert.message}
                    values={
                      alert.transValue
                        ? { maxFields: alert.transValue }
                        : undefined
                    }
                  />
                ) : (
                  alert.message
                )}
              </p>
            </AlertBodyContent>
            {alert.image ? (
              <AlertImageWrapper>
                <img src={alert.image} />
              </AlertImageWrapper>
            ) : null}
          </AlertBody>

          <AlertFooter>{alert.buttons}</AlertFooter>
        </AlertWrapper>
        {alert.hasProgressBar && alert.dismissableTimer ? (
          <AlertProgressBar timer={alert.dismissableTimer} />
        ) : undefined}
      </Container>
    </AlertStyled>
  )
}
