import React from 'react'
import { SignupLayout } from '@features/signup/SignupLayout'
import { Content } from '@features/signup/SignupContent/Content'
import { SignUpForm } from '@features/signup/SignupForm'
import { useTranslation } from 'react-i18next'
import signupGenericImage from '@assets/images/signup-generic.svg'
import lbLogo from '@assets/images/logo_lb_extended.svg'
import heartImg from '@assets/images/heart.svg'
import appSumoLogo from '@assets/images/appsumo-logo.svg'

const appSumoHeader = (
  <>
    <img src={lbLogo} width={140} alt={'LeadsBridge'} />
    <img src={heartImg} style={{ margin: '0 .5rem' }} alt={'heart'} />
    <img src={appSumoLogo} alt={'appSumo'} width={100} height={16} />
  </>
)

export const SignUpAppSumo: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <SignupLayout
        hideLeftOnMobile
        leftSlot={
          <Content
            section={<img src={signupGenericImage} />}
            headerText={t('auth.signup.headline')}
            subText={t('auth.signup.subHeadline')}
          />
        }
        rightSlot={
          <SignUpForm
            title={t('auth.signup.formTitleAppSumo')}
            isAppSumo
            showIndustries
            showCoupon
            header={appSumoHeader}
          />
        }
      />
    </>
  )
}
