import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { makeGoogleAccountSteps } from '@features/googleAdsAccount/utils'
import { PanelBody } from '@features/googleAdsAccount/AccountConnect/PanelBody'
import { Button } from '@components/Basic/ButtonNbe'
import { useHistory } from 'react-router-dom'

export const AccountConnect: React.FC = () => {
  const { t } = useTranslation()
  const steps = makeGoogleAccountSteps(2)
  const history = useHistory()
  return (
    <PageEditor
      steps={steps}
      activeStep={2}
      topbarValue={t('gaac.topbarTitle')}
      hasTopBar
      backlink={{
        url: '/app/googleAdsAccounts',
        label: t('gaac.topbarExitButton'),
      }}
    >
      <Panel
        body={<PanelBody />}
        footer={{
          left: null,
          right: (
            <a href={'/app/bridges'}>
              <Button
                data-testid='connect_later_btn'
                type={'submit'}
                $variant={'link-primary'}
              >
                {t('gaac.steps.stepConnect.buttonConnectLater')}
              </Button>
            </a>
          ),
        }}
      />
    </PageEditor>
  )
}
