import React, { InputHTMLAttributes } from 'react'
import { InputDomainStyled } from '@components/Form/InputDomain/styled'

export interface InputDomainProps
  extends InputHTMLAttributes<HTMLInputElement> {
  $protocolLabel: string
  $domainLabel: string
}

export const InputDomain: React.FC<InputDomainProps> = ({
  $protocolLabel,
  $domainLabel,
  ...props
}) => {
  return (
    <InputDomainStyled>
      <label>{$protocolLabel}</label>
      <input {...props} type='text' />
      <label>{$domainLabel}</label>
    </InputDomainStyled>
  )
}
