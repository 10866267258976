import React from 'react'
import {
  ContainerStyled,
  ImgBoxStyled,
  ImgWrapperStyled,
  HeaderTextStyled,
  IconWrapperStyled,
} from './styled'
import noDataPng from '@assets/images/no-data.png'
import { Loader } from '@components/Basic/Loader'
import { AiOutlinePlusCircle } from 'react-icons/ai'

interface ImgSquareProps {
  logoUri?: string
  name?: string
  isLoading?: boolean
}

const ImgSquare = ({ logoUri, name, isLoading }: ImgSquareProps) => (
  <ImgBoxStyled>
    {isLoading ? (
      <Loader $active />
    ) : (
      <>
        <img src={logoUri || noDataPng} alt={name} />
        {name && <p>{name}</p>}
      </>
    )}
  </ImgBoxStyled>
)

interface BbuContentProps {
  sourceLogoUri?: string
  sourceName?: string
  destinationLogoUri?: string
  destinationName?: string
  headline?: string
}
export const BbuContent: React.FC<BbuContentProps> = ({
  sourceLogoUri,
  sourceName,
  destinationLogoUri,
  destinationName,
  headline,
}) => {
  return (
    <ContainerStyled>
      <ImgWrapperStyled>
        <ImgSquare logoUri={sourceLogoUri} name={sourceName} />
        <IconWrapperStyled>
          <span />
          <AiOutlinePlusCircle style={{ margin: '0' }} color='white' />
          <span />
        </IconWrapperStyled>
        <ImgBoxStyled>
          <ImgSquare logoUri={destinationLogoUri} name={destinationName} />
        </ImgBoxStyled>
      </ImgWrapperStyled>
      {headline ? (
        <HeaderTextStyled>
          {headline ||
            `Use LeadsBridge to connect ${sourceName} with ${destinationName}`}
        </HeaderTextStyled>
      ) : null}
    </ContainerStyled>
  )
}
