import React from 'react'
import { SignupLayout } from '@features/signup/SignupLayout'
import { Content } from '@features/signup/SignupContent/Content'
import { SignUpForm } from '@features/signup/SignupForm'
import { BbuContent } from '@features/signup/BbuContent'
import { useTranslation } from 'react-i18next'
import { BbuRouteParams } from 'LeadsBridgeApp'
import { useParams } from 'react-router-dom'
import { useFetchIntegrationsMetaForBridgeByUrl } from '@app/api/integrationTools'
import { Loader } from '@components/Basic/Loader'
import signupGenericImage from '@assets/images/signup-generic.svg'

// example url for testing:
// http://localhost:3000/bc/lb/facebook-lead-ads/google-sheets
export const SignUpBbu: React.FC = () => {
  const { t } = useTranslation()
  const { source, destination }: BbuRouteParams = useParams()

  const { isLoading, data: bbuAppsInfo } =
    useFetchIntegrationsMetaForBridgeByUrl(source, destination)

  // prepare content in case of bbu
  const sourceName = bbuAppsInfo?.source?.name
  const sourceLogoUri = bbuAppsInfo?.source.logoSmallUri
  const destinationName = bbuAppsInfo?.destination?.name
  const destinationLogoUri = bbuAppsInfo?.destination.logoSmallUri
  const headline = bbuAppsInfo?.meta?.headline
  const isValidBbuUri = Boolean(sourceName && destinationName)

  return (
    <>
      <SignupLayout
        leftSlot={
          <Content
            section={
              isLoading ? (
                <div style={{ position: 'relative' }}>
                  <Loader $active $inverted />
                </div>
              ) : isValidBbuUri ? (
                <BbuContent
                  sourceName={sourceName}
                  sourceLogoUri={sourceLogoUri}
                  destinationLogoUri={destinationLogoUri}
                  destinationName={destinationName}
                  headline={headline}
                />
              ) : (
                // if is not a valid bbuUri, we display the default signup content as fallback
                <Content
                  section={<img src={signupGenericImage} />}
                  headerText={t('auth.signup.headline')}
                  subText={t('auth.signup.subHeadline')}
                />
              )
            }
          />
        }
        rightSlot={
          <SignUpForm
            title={t('auth.signup.formTitle')}
            showGoogleButton
            showFacebookButton
          />
        }
      />
    </>
  )
}
