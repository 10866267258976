import React, { useEffect, useState } from 'react'
import { Message, Transition } from 'semantic-ui-react'
import { Toast } from 'LeadsBridgeApp'
import { useSelector } from 'react-redux'
import { RootState } from '@app/index'
import styled from 'styled-components'

const ToastStyled = styled.div`
  position: fixed;
  top: 33px;
  right: 10px;
  z-index: ${({ theme }) => theme.zIndex.overallNotifications};
  .ui.message {
    padding-right: 40px;
  }
`

const ToastComponent: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)
  const toast: Toast | null = useSelector(
    (state: RootState) => state.application.toast
  )

  useEffect(() => {
    if (toast !== null) {
      setVisible(true)
      setTimeout(() => {
        setVisible(false)
      }, 5500)
    }
  }, [toast])

  return (
    <ToastStyled>
      <Transition visible={visible} animation='scale' duration={500}>
        <Message
          compact
          className={toast?.color}
          header={toast?.title}
          onDismiss={() => setVisible(false)}
        >
          {toast && toast.message ? (
            <span dangerouslySetInnerHTML={{ __html: toast.message }} />
          ) : null}
          {toast && toast.messages ? (
            <ul>
              {toast.messages.map((message, index) => {
                return (
                  <li
                    key={index}
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )
              })}
            </ul>
          ) : null}
        </Message>
      </Transition>
    </ToastStyled>
  )
}

export default ToastComponent
