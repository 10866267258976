import { SelectValueMulti } from '@components/Form/MultiCreatableCustom'
import { ApiFormulasSchemaResponseData, ApiUserModuleItem } from 'BackendApi'

export interface RowProps {
  index: number
  selectOptions: SelectValueMulti[]
  formulaUserModule?: ApiUserModuleItem | undefined
  showWelcomeMessage?: boolean
  formulaSchema?: ApiFormulasSchemaResponseData[]
  sourceLogoUri?: string
  onRedirectToPricing?: (redirect: boolean) => void
}

type ErrorObject = {
  [key: string]: any
}

// function to flatten the hasError object and extract the errors from the params
export const flattenErrors = (errorObject: ErrorObject): string[] => {
  return Object.entries(errorObject).reduce(
    (messages: string[], [key, value]) => {
      if (typeof value === 'object') {
        // when we have a formula,
        // checks if we need to retrieve the error message
        // from the values in the param array
        const subErrors = flattenErrors(value as ErrorObject)
        return [...messages, ...subErrors]
      } else {
        return [...messages, value as string]
      }
    },
    []
  )
}
