import React, { useEffect } from 'react'
import { PageEditor } from '@app/layouts/PageEditor'
import { makeNbeeSteps } from '@features/nbee/utils'
import { useTranslation } from 'react-i18next'
import { Message } from '@components/Basic/Message'
import { Button } from '@components/Basic/ButtonNbe'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { trackEvent } from '@app/dataTracking'
import { isInIframe } from '@app/utils/isInFrame'

interface Props {
  errorMessage: string
  activeStepIndex: number
  bridgeId?: number | string
  statusCode?: number
  errorCode: number | string
}

export const NbeeError: React.FC<Props> = ({
  errorMessage,
  activeStepIndex,
  bridgeId,
  statusCode,
  errorCode,
}) => {
  const steps = makeNbeeSteps(activeStepIndex, bridgeId)
  const { t } = useTranslation()
  const history = useHistory()
  const isEmbedded = isInIframe()

  useEffect(() => {
    trackEvent({
      eventName: 'UnableToLoadBridge',
      feature: 'NBEE',
      step: 'Apps',
      params: {
        bridgeId: bridgeId ? parseInt(`${bridgeId}`, 10) : undefined,
        custom: {
          errorCode: errorCode || statusCode,
          errorDescription: errorMessage,
        },
      },
      sendEventToIntercom: true,
    })
  }, [bridgeId])

  return (
    <PageEditor
      steps={steps}
      activeStep={activeStepIndex}
      pageTitle={t('common.genericError')}
      hasTopBar={!isEmbedded}
      backlink={{
        url: '/bridges',
        label: 'Exit',
      }}
    >
      <Message $status={'error'}>{errorMessage}</Message>

      {/* Go back button only in ca  */}
      {statusCode === 404 || bridgeId ? (
        <Button
          $variant={'primary'}
          onClick={() => {
            if (statusCode === 404) {
              window.location.href = `${process.env.REACT_APP_V1_URL}/bridges`
              return
            }

            if (bridgeId) {
              history.push(appRoutes.nbeeStep1Edit.makeUrl(`${bridgeId}`))
            }
          }}
        >
          {t('common.goBack')}
        </Button>
      ) : null}
    </PageEditor>
  )
}
