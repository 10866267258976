import React from 'react'
import { StyledSkeleton } from '@components/Basic/Skeleton/styled'
import {
  SkeletonAllowedElement,
  skeletonElementSizes,
  SkeletonSizes,
} from '@components/Basic/Skeleton/elementSizes'

interface SkeletonProps {
  width?: string
  height?: string
  forElement?: SkeletonAllowedElement
}

const getDefaultSize = (width?: string, height?: string): SkeletonSizes => ({
  width: width || '100%',
  height: height || '1.4em',
  marginBottom: '0',
  marginTop: '0',
})

const getSpecificSize = (forElement: SkeletonAllowedElement) =>
  skeletonElementSizes[forElement] || getDefaultSize()

export const Skeleton: React.FC<SkeletonProps> = ({
  width,
  height,
  forElement,
}) => {
  const hasCustomSize = width && height
  if (!hasCustomSize && !forElement) {
    console.warn(
      'Custom size or a specific element is required to use skeleton'
    )
    return null
  }

  const defaultSizes = getDefaultSize(width, height)

  const computedSizes = forElement ? getSpecificSize(forElement) : defaultSizes

  return (
    <StyledSkeleton
      width={computedSizes.width}
      height={computedSizes.height}
      marginBottom={computedSizes.marginBottom}
      marginTop={computedSizes.marginTop}
    />
  )
}
