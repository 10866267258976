import React, { ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

export type Props = ButtonHTMLAttributes<HTMLButtonElement>

const RefreshButton = styled.button`
  cursor: pointer;
  font-size: 0.8rem;
  background: #fff;
  outline: none;
  border: 0;
  padding: 0;
  font-family: ${({ theme }) => theme.font.family};
  display: inline-flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.color.link};
  }

  svg {
    margin-right: 0.3rem;
  }
`

export const TinyTextualButton: React.FC<Props> = ({
  children,
  type = 'button',
  ...rest
}) => {
  return (
    <RefreshButton type={type} {...rest}>
      {children}
    </RefreshButton>
  )
}
