import styled, { css } from 'styled-components'

interface ContainerProps {
  $size?: 'md' | 'lg' | 'fluid'
  $marginY?: string
}

const getContainerSize = ({ $size = 'md' }: ContainerProps) => {
  switch ($size) {
    case 'lg':
      return css`
        max-width: ${(props) => props.theme.container.large};
      `
    case 'md':
      return css`
        max-width: ${(props) => props.theme.container.medium};
      `
  }
}

export const Container = styled.div<ContainerProps>`
  ${getContainerSize};

  margin: 0 auto;
  ${({ $marginY }) =>
    $marginY !== ''
      ? css`
          margin-top: ${$marginY};
          margin-bottom: ${$marginY};
        `
      : ''}
`
