import React from 'react'
import { useTranslation } from 'react-i18next'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { makeGoogleAccountSteps } from '@features/googleAdsAccount/utils'
import { PanelBody } from '@features/googleAdsAccount/AccountCreationDone/PanelBody'
import { useHistory, useLocation } from 'react-router-dom'

export const AccountCreationDone: React.FC = () => {
  const { t } = useTranslation()
  const steps = makeGoogleAccountSteps(0)
  const history = useHistory()
  const location = useLocation()

  return (
    <PageEditor
      steps={steps}
      activeStep={0}
      topbarValue={t('gaac.topbarTitle')}
      hasTopBar
      backlink={{
        url: '/app/googleAdsAccounts',
        label: t('gaac.topbarExitButton'),
      }}
    >
      <Panel body={<PanelBody />} />
    </PageEditor>
  )
}
