import React, { useEffect } from 'react'

type WindowWithGrecaptchh = Window &
  typeof globalThis & {
    grecaptcha: any
  }

export type GetCaptchaToken = () => Promise<string | undefined>

interface Props {
  children: (renderProps: {
    getCaptchaToken: GetCaptchaToken
  }) => React.ReactNode
}

const googleReCaptchaKey = process.env.REACT_GOOGLE_RECAPTCHA_KEY || ''

export const ReCaptcha: React.VFC<Props> = ({ children }) => {
  // we generate the token everytime a child component calls this method
  const getCaptchaToken: GetCaptchaToken = async () => {
    if (!googleReCaptchaKey) {
      return 'recaptcha-not-enabled'
    }

    const w = window as WindowWithGrecaptchh
    if (!w.grecaptcha) {
      console.log('recaptcha lib not found')
      return
    }

    try {
      const token: string = await w.grecaptcha.execute(
        `${process.env.REACT_GOOGLE_RECAPTCHA_KEY}`,
        {
          action: 'submit',
        }
      )
      return token
    } catch (e: any) {
      console.log(e, e.message)
      return 'cannot-retrieve-recaptcha-token'
    }
  }

  useEffect(() => {
    if (!googleReCaptchaKey) {
      return
    }

    if (document.getElementById('google-recaptcha')) {
      console.log('Captcha script exists already')
      return
    }

    // Inject recaptcha script in DOM
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${googleReCaptchaKey}`
    script.id = 'google-recaptcha'
    document.body.appendChild(script)

    // cleanup function to remove script on component unmount
    return () => {
      const googleScript = document.getElementById('google-recaptcha')
      // remove element in DOM
      document.querySelector('.grecaptcha-badge')?.remove()
      if (googleScript) {
        googleScript.remove()
      }
    }
  }, [])

  return <>{children({ getCaptchaToken })}</>
}
