import React, { useState } from 'react'
import styled from 'styled-components'
import { FacebookButtonNative } from '@components/ButtonProvider/Facebook/FacebookButtonNative'
import { InputField } from '@components/Form/InputField'
import { Button } from '@components/Basic/ButtonNbe'
import { apiAuthClient } from '@app/services/apiAuthClient'
import { useAppDispatch } from '@app/store/hooks'
import { sendToast } from '@app/store/actions/ApplicationConfigurationActions'

const Wrapper = styled.div`
  padding: 2rem;
`

const TokenOutput = styled.div`
  padding-top: 1rem;
`

const Spacer = styled.div`
  margin-bottom: 1rem;
`

export const TestFacebookToken: React.VFC = () => {
  const [token, setToken] = useState('')
  const [inputValue, setInputValue] = useState(
    'pages_manage_ads,pages_manage_metadata,pages_read_engagement,ads_management,email,leads_retrieval,business_management'
  )
  const [scopes, setScopes] = useState('')
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const validateTokenWithApi = (token: string) => {
    setIsLoading(true)
    apiAuthClient
      .post(`${process.env.REACT_APP_LARAVEL_API}/admin/testFacebookToken`, {
        oauthToken: token,
      })
      .then(() => {
        dispatch(
          sendToast({
            title: 'Success',
            message: 'Request completed',
            color: 'positive',
          })
        )
      })
      .catch(() => {
        setIsLoading(false)
        dispatch(
          sendToast({
            title: 'Error',
            message: 'Request failed',
            color: 'negative',
          })
        )
      })
  }

  return (
    <Wrapper>
      <Spacer>
        <InputField
          label={'Scopes'}
          name={''}
          type={'text'}
          disabled={isLoading}
          value={inputValue}
          placeholder={'add scope as comma separated values'}
          onChange={(e) => setInputValue(e.currentTarget.value)}
        />
        <Button
          disabled={!inputValue || isLoading}
          onClick={() => {
            setScopes(inputValue)
          }}
          $variant={'action'}
        >
          Set scopes
        </Button>
      </Spacer>

      <Spacer>
        {scopes ? (
          <div style={{ width: 240 }}>
            <FacebookButtonNative
              key={scopes}
              onTokenRetrived={setToken}
              scope={'SignIn'}
              onSdkLoadError={() => {
                alert('error loading FB SDK')
              }}
              customFbAppScopes={scopes}
            />
          </div>
        ) : (
          <div>Add some scopes to see the FB login button</div>
        )}
      </Spacer>

      {token ? (
        <Spacer>
          <TokenOutput>
            <InputField
              label={'Auth token'}
              name={''}
              type={'text'}
              readOnly
              disabled
              value={token}
              placeholder={'Auth token will appear here'}
            />
          </TokenOutput>
          <div>
            <Button
              $variant={'primary'}
              disabled={isLoading}
              onClick={() => {
                validateTokenWithApi(token)
              }}
            >
              Validate token
            </Button>
          </div>
        </Spacer>
      ) : null}
    </Wrapper>
  )
}
