import React, { Suspense } from 'react'

const PricingLazy = React.lazy(() => import('./PricingPageComponent'))

export const PricingPage = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: 'relative',
            height: 200,
          }}
        ></div>
      }
    >
      <PricingLazy />
    </Suspense>
  )
}
