import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { BridgeFormValues } from 'Nbee'
import { useTranslation } from 'react-i18next'
import { Loader } from '@components/Basic/Loader'
import { Message } from '@components/Basic/Message'
import { parseApiError } from '@app/api/utils/error'
import { useGetBridgeTestableFields } from '@app/api/getBridgeTestableFields'
import { tranformApiResponseToFormValues } from '@features/nbee/FieldsMappingForm/SendTestLeadSubForm/utils'
import { ApiBridgeAvailablePermissionCheckResponse } from 'BackendApi'
import { SendTestLeadSubForm } from '../SendTestLeadSubForm'
interface Props {
  bridgeId: number
  availablePermissionChecks:
    | ApiBridgeAvailablePermissionCheckResponse
    | undefined
}

export const TestConnectionView: React.VFC<Props> = ({ bridgeId }) => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()

  const fieldsToRender = values.ui?.fieldMappingTesting || []
  const fieldMappingTestingFieldName = `ui.fieldMappingTesting`

  const {
    data: fieldsToTestResponse,
    error: errorGettingFieldsToTest,
    isLoading: isLoadingFieldsToTest,
    isRefetching: isRefetchingFieldsToTest,
  } = useGetBridgeTestableFields(bridgeId)

  const isLoadingAll = isLoadingFieldsToTest || isRefetchingFieldsToTest

  const apiError = errorGettingFieldsToTest

  useEffect(() => {
    if (fieldsToTestResponse) {
      // we update formik state with the fields to render
      const sourceFieldsToTest =
        tranformApiResponseToFormValues(fieldsToTestResponse)
      setFieldValue(fieldMappingTestingFieldName, sourceFieldsToTest)
    }
  }, [fieldsToTestResponse])

  return (
    <>
      {isLoadingAll ? (
        <div>
          <Loader $active $size={'large'} $text={t('common.loadingData')} />
        </div>
      ) : apiError ? (
        <Message $status={'error'}>{parseApiError(apiError).message}</Message>
      ) : (
        <>
          <SendTestLeadSubForm
            fieldsToRender={fieldsToRender}
            setFieldValue={setFieldValue}
            fieldMappingTestingFieldName={fieldMappingTestingFieldName}
          />
        </>
      )}
    </>
  )
}
