import React, { useEffect } from 'react'
import { StyledAuthorizationPopup } from './styled'
import LbLogoSvg from '@assets/images/logo-lb-small.svg'
import { Trans, useTranslation } from 'react-i18next'
import {
  AppAuthCredentialForm,
  SubmitAction,
} from '@features/nbee/IntegrationCredentialsContent/AppAuthCredentialForm'
import { IntegrationFormValues } from 'Nbee'
import { ApiErrorResponse } from 'BackendApi'
import { FormikHelpers } from 'formik'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { BiShieldQuarter } from 'react-icons/bi'

interface Props {
  appId: number
  bridgeId?: number
  appName: string
  appLogoUri: string
  onSubmit: (
    formValues: IntegrationFormValues,
    formikHelpers: FormikHelpers<IntegrationFormValues>,
    action: SubmitAction
  ) => void
  initialValues: IntegrationFormValues
  isSavingApi: boolean
  isOauthUri?: boolean
  // skip step1 (params fields) and load the form at the integration name (final) step
  skipParams?: boolean
  // in case of edit we also need the integrationId we want to update
  integrationId?: number
  // when we receive an error after testing the credentials, we show it inline in the first scrren
  isNotValidIntegrationErrorMessage?: string
}

export const IntegrationCredentialsContent: React.FC<Props> = ({
  appId,
  bridgeId,
  appName,
  appLogoUri,
  onSubmit,
  initialValues,
  isSavingApi,
  isOauthUri,
  skipParams,
  integrationId,
  isNotValidIntegrationErrorMessage,
}) => {
  const { t } = useTranslation()

  const { Wrapper, Header, Disclaimer, Body, Logo, Title, SubTitle } =
    StyledAuthorizationPopup

  const isFinalStepFromOauthFlow = isOauthUri && skipParams

  return (
    <Wrapper>
      <Header>
        <img src={LbLogoSvg} width='40' />
      </Header>

      <Body>
        {isFinalStepFromOauthFlow ? (
          <>
            <div style={{ margin: '2rem 0', textAlign: 'center' }}>
              <AiOutlineCheckCircle size={'90'} color='#2ECD72' />
              <Title>
                {t('integrationAuthPopup.textSuccess', {
                  appName,
                })}
              </Title>
            </div>
          </>
        ) : (
          <>
            <Logo src={appLogoUri} alt={appName} />
            <Title>
              {t('integrationAuthPopup.authorizationTitle', {
                appName,
              })}
            </Title>
            <SubTitle>
              {t('integrationAuthPopup.authorizationSubtitle', {
                appName,
              })}
            </SubTitle>
          </>
        )}

        <AppAuthCredentialForm
          appId={appId}
          appName={appName}
          initialValues={initialValues}
          onSubmit={onSubmit}
          isSavingApi={isSavingApi}
          isOauthUri={isOauthUri}
          skipParams={skipParams}
          integrationId={integrationId}
          bridgeId={bridgeId}
          isNotValidIntegrationErrorMessage={isNotValidIntegrationErrorMessage}
        />

        <Disclaimer>
          <BiShieldQuarter size={'1.2rem'} />
          <div>
            <Trans
              ns={'all'}
              i18nKey={'integrationAuthPopup.disclaimerLine'}
              components={{
                a: <a />,
              }}
            />
          </div>
        </Disclaimer>
      </Body>
    </Wrapper>
  )
}
