import React, { Fragment } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { Button } from '@components/Basic/ButtonNbe'
import { makeGoogleAccountSteps } from '@features/googleAdsAccount/utils'
import { AccountCreationFormInner } from '@features/googleAdsAccount/AccountCreationFormInner'
import { useHistory } from 'react-router-dom'
import { GoogleAdsAccountFormValues } from 'GoogleAdsAccount'
import { googleAdsAccountFormValidationSchema } from '@features/googleAdsAccount/AccountCreationForm/validation'

interface StepAccountConnectProps {
  initialValues: GoogleAdsAccountFormValues
  onSubmit: (
    formValues: GoogleAdsAccountFormValues,
    formikHelpers: FormikHelpers<GoogleAdsAccountFormValues>
  ) => void
  isSavingApi?: boolean
}

export const AccountCreationForm: React.FC<StepAccountConnectProps> = ({
  initialValues,
  onSubmit,
  isSavingApi,
}) => {
  const { t } = useTranslation()
  const steps = makeGoogleAccountSteps(0)
  const history = useHistory()
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={googleAdsAccountFormValidationSchema}
      enableReinitialize={true}
      validateOnMount
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers)
      }}
    >
      {(formikProps) => {
        // here we handle all cases where button is disabled

        return (
          <Fragment>
            <PageEditor
              steps={steps}
              activeStep={0}
              topbarValue={t('gaac.topbarTitle')}
              hasTopBar
              backlink={{
                url: '/app/googleAdsAccounts',
                label: t('gaac.topbarExitButton'),
              }}
            >
              <Form>
                <Panel
                  body={<AccountCreationFormInner />}
                  footer={{
                    left: null,
                    right: (
                      <Button
                        type={'submit'}
                        $loading={isSavingApi}
                        disabled={!formikProps.isValid || isSavingApi}
                        data-testid={'create_btn'}
                        $variant={'primary'}
                      >
                        {t('common.continue')}
                      </Button>
                    ),
                  }}
                />
              </Form>
            </PageEditor>
          </Fragment>
        )
      }}
    </Formik>
  )
}
