import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { appRoutes, bridgeByUrlPartnersPaths } from '@app/routes'
import { Step1Edit } from '@app/pages/Nbee/Step1Edit'
import { Step1 } from '@app/pages/Nbee/Step1'
import { Step2 } from '@app/pages/Nbee/Step2'
import { NbeeHome } from '@app/pages/Nbee/NbeeHome'
import { Step3 } from '@app/pages/Nbee/Step3'
import { BridgeChooser } from '@app/pages/Nbee/BridgeChooser'
import { InfoPage } from '@features/nbee/StepCompleted/InfoPage'

export const NbeeRouter: React.FC = () => {
  return (
    <Switch>
      <Route exact path={appRoutes.nbeeInfoPage.path} component={InfoPage} />
      <Route
        exact
        path={[
          appRoutes.nbeeBridgeChooserStep1.path,
          appRoutes.nbeeBridgeChooserStep2.path,
        ]}
        component={BridgeChooser}
      />
      <Route exact path={appRoutes.nbeeStep1New.path} component={Step1} />
      <Route
        exact
        path={[
          appRoutes.nbeeAppBbu.path,
          appRoutes.signupBbu.path,
          ...bridgeByUrlPartnersPaths,
        ]}
        component={Step1}
      />
      <Route exact path={appRoutes.nbeeStep1Edit.path} component={Step1Edit} />
      <Route exact path={appRoutes.nbeeStep2.path} component={Step2} />
      <Route exact path={appRoutes.nbeeStep3.path} component={Step3} />
      <Route component={NbeeHome} />
    </Switch>
  )
}
