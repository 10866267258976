import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ignoreList } from '@app/utils/sentry/ignoreList'

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://acad65fcd1a943a893a7e2dfb763ebf7@o320992.ingest.sentry.io/6172904',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/#sampling-transaction-events
    tracesSampleRate: 0,
    environment: process.env.DEPLOY_ENV,
    release: APP_VERSION,
    // loading Sentry only for production
    enabled: process.env.NODE_ENV === 'production',
    ...ignoreList,
  })
}
