import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { StepCompletedWrapper, StepImage } from './styled'
import { ApiErrorResponse } from 'BackendApi'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { Trans, useTranslation } from 'react-i18next'
import { makeNbeeSteps } from '@features/nbee/utils'
import { useHistory } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import FinishImage from '@assets/images/illustrations/nbee-completed.svg'
import { isInIframe } from '@app/utils/isInFrame'
import Confetti from '@components/Confetti'
import { useQueryClient } from 'react-query'
import { Icon } from 'semantic-ui-react'
import { PerformanceBoosterBanner } from '@features/nbee/StepCompleted/PerformanceBoosterBanner'
import { PerformanceBoosterTip } from '@features/nbee/StepCompleted/PerformanceBoosterTip'

interface Props {
  bridgeName: string
  bridgeId: string
  sourceName?: string
  destinationName?: string
  redirectUri?: string
  isTestEnabledProp?: boolean
  performanceBoosterAvailable?: boolean
  errorPerformanceBooster?: ApiErrorResponse | null
  existingPerformanceBoosterAvailable?: boolean
  handleCreatePerformanceBoosterAndRedirect?: () => void
  existingPbId?: number
}

export const StepCompleted: React.FC<Props> = ({
  bridgeId,
  bridgeName,
  sourceName,
  destinationName,
  redirectUri,
  isTestEnabledProp,
  performanceBoosterAvailable,
  existingPerformanceBoosterAvailable,
  errorPerformanceBooster,
  handleCreatePerformanceBoosterAndRedirect,
  existingPbId,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const steps = makeNbeeSteps(2, bridgeId)
  const isEmbedded = isInIframe()

  const queryClient = useQueryClient()

  return (
    <PageEditor
      steps={steps}
      activeStep={2}
      topbarValue={bridgeName}
      hasTopBar={!isEmbedded}
      bridgeIdProp={bridgeId}
      IsTestEnableProp={isTestEnabledProp}
      backlink={{
        url: '/bridges',
        label: t('nbee.bridgeBuilder.exitNbee'),
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft:
            performanceBoosterAvailable && !errorPerformanceBooster
              ? '190px'
              : 0,
        }}
      >
        <Panel
          progressBarPercentage={isEmbedded ? 100 : undefined}
          body={
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Confetti />
              <StepCompletedWrapper>
                {performanceBoosterAvailable && !errorPerformanceBooster && (
                  <Icon
                    size='big'
                    name={'check circle'}
                    style={{
                      color: '#2fcc71',
                      margin: 0,
                      padding: 0,
                      alignSelf: 'center',
                    }}
                  />
                )}

                <h3
                  style={{
                    marginTop: '16px',
                    marginBottom: '16px',
                    fontSize:
                      performanceBoosterAvailable && !errorPerformanceBooster
                        ? '18px'
                        : undefined,
                  }}
                >
                  {t('nbee.bridgeBuilder.step3SuccessHeader')}
                </h3>
                <p
                  style={{
                    margin: '0 0 16px 0',
                  }}
                >
                  <Trans
                    ns={'all'}
                    i18nKey={'nbee.bridgeBuilder.step3SuccessBody'}
                    components={{
                      strong: <strong />,
                      bridgeName: bridgeName,
                      sourceName: sourceName,
                      destinationName: destinationName,
                    }}
                  />
                </p>
                {performanceBoosterAvailable && !errorPerformanceBooster && (
                  <p
                    style={{
                      fontWeight: 'bold',
                      margin: 0,
                    }}
                  >
                    {t('nbee.bridgeBuilder.step3Enabled')}
                  </p>
                )}

                {(!performanceBoosterAvailable || errorPerformanceBooster) && (
                  <div style={{ marginTop: '12px', alignSelf: 'center' }}>
                    <a
                      rel='noreferrer'
                      href={redirectUri}
                      target={isInIframe() ? '_blank' : undefined}
                    >
                      <Button $variant={'primary'}>
                        {t('nbee.bridgeBuilder.exploreLeadsBridge')}
                      </Button>
                    </a>
                  </div>
                )}

                {performanceBoosterAvailable && !errorPerformanceBooster && (
                  <PerformanceBoosterBanner
                    existingPerformanceBoosterAvailable={
                      existingPerformanceBoosterAvailable
                    }
                    handleCreatePerformanceBoosterAndRedirect={
                      handleCreatePerformanceBoosterAndRedirect
                    }
                    existingPbId={existingPbId}
                    bridgeId={bridgeId}
                  />
                )}

                {!performanceBoosterAvailable && !errorPerformanceBooster && (
                  <StepImage
                    src={FinishImage}
                    alt={t('nbee.bridgeBuilder.step3Title')}
                  />
                )}

                {performanceBoosterAvailable && !errorPerformanceBooster && (
                  <div>
                    <a
                      rel='noreferrer'
                      href={redirectUri}
                      target={isInIframe() ? '_blank' : undefined}
                    >
                      <Button
                        $variant={'link-primary'}
                        style={{
                          textDecoration: 'underline',
                          padding: 0,
                          fontWeight: 400,
                        }}
                      >
                        {t('nbee.bridgeBuilder.backToBridges')}
                      </Button>
                    </a>
                  </div>
                )}
              </StepCompletedWrapper>
            </div>
          }
          footer={{
            left: (
              <Button
                type={'button'}
                $variant={'link-secondary'}
                onClick={() => {
                  // we want to invalidate the permission checks query when the user goes back from here. This way the information will be refetched once the user lands on step2
                  queryClient.invalidateQueries(['permission-checks'])
                  history.push(appRoutes.nbeeStep2.makeUrl(bridgeId))
                }}
              >
                {t('nbee.fieldsMapping.goPreviewStep')}
              </Button>
            ),
            right: (
              <div style={{ display: 'flex', gap: '1rem' }}>
                <Button
                  type={'button'}
                  $variant={'secondary'}
                  onClick={() => {
                    history.push(appRoutes.nbeeBridgeChooserStep1.makeUrl())
                  }}
                >
                  {t('nbee.fieldsMapping.makeNewBridge')}
                </Button>
              </div>
            ),
          }}
        />
        {performanceBoosterAvailable && !errorPerformanceBooster && (
          <PerformanceBoosterTip />
        )}
      </div>
    </PageEditor>
  )
}
