import React, { SelectHTMLAttributes } from 'react'
import { SelectStyled, SelectWrapper } from './styled'
import { Label } from '@components/Form/Label'
import {
  InputFeedback,
  InputFeedbackStatus,
} from '@components/Basic/InputFeedback'
import { FiChevronDown } from 'react-icons/fi'

export type InputSelectOption = {
  value: string
  label: string
  disabled?: boolean
}

export interface SelectFieldProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string
  disabled?: boolean
  $loading?: boolean
  $label?: string
  $direction?: 'column' | 'row'
  $options: InputSelectOption[]
  $optionsLabel?: string
  $status?: InputFeedbackStatus
  $hasMargin?: boolean
}

export const InputSelect: React.FC<SelectFieldProps> = ({
  name,
  disabled,
  $loading,
  $direction,
  $label,
  $options,
  $optionsLabel,
  $status,
  $hasMargin,
  ...props
}) => {
  const selectId = `${name}Select`
  return (
    <SelectStyled
      $options={$options}
      $direction={$direction || 'column'}
      name={name}
      $optionsLabel={$optionsLabel}
      $status={$status}
      $hasMargin={$hasMargin}
    >
      {$label && (
        <Label $disabled={disabled} htmlFor={selectId} $label={$label} />
      )}
      <SelectWrapper>
        <select
          name={name}
          id={selectId}
          {...props}
          disabled={disabled || $loading}
        >
          {$optionsLabel && (
            <option value=''>{$loading ? 'Loading...' : $optionsLabel}</option>
          )}
          {$options &&
            $options.map((option) => (
              <React.Fragment key={option.value}>
                <option value={option.value} disabled={option.disabled}>
                  {option.label}
                </option>
              </React.Fragment>
            ))}
        </select>
        <div>
          <FiChevronDown />
        </div>
      </SelectWrapper>
      {$status && <InputFeedback $status={$status} />}
    </SelectStyled>
  )
}
