import React from 'react'
import { Trans } from 'react-i18next'

interface Props {
  i18nKey: string
  values?: Record<string, string | number>
  count?: number
}

export const Translate: React.FC<Props> = ({ i18nKey, values, count }) => {
  return (
    <Trans
      i18nKey={i18nKey as never}
      components={{
        b: <strong />,
        strong: <strong />,
        a: <a />,
        br: <br />,
      }}
      values={values}
      count={count}
    />
  )
}
