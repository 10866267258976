import React from 'react'
import {
  ContainerStyled,
  IconWrapperStyled,
  FastAppSurveyWrapperStyled,
  HeaderStyled,
  LoaderWrapperStyled,
  LogoStyled,
  BrandLogoStyled,
} from './styled'
import { Loader } from '@components/Basic/Loader'
import { ProgressBar } from '@components/Basic/ProgressBar'
import { Form, FormProps } from '@components/FastAppSurvey/Form'
import logo from '@assets/images/logo-lb.png'
import { AiOutlineArrowRight } from 'react-icons/ai'

export interface Props extends Pick<FormProps, 'onSubmitSuccess'> {
  isLoading?: boolean
  loadingText?: string
  step: 0 | 1 | 2
  integrationsData?: {
    source?: {
      name: string
      logo: string
    }
    destination?: {
      name: string
      logo: string
    }
  }
}

export const FastAppSurvey: React.FC<Props> = ({
  step,
  isLoading,
  loadingText,
  integrationsData,
  onSubmitSuccess,
}) => {
  return (
    <ContainerStyled>
      <BrandLogoStyled>
        <img src={logo} alt='' />
      </BrandLogoStyled>
      <HeaderStyled>
        <h2>{`We're setting up your connection`}</h2>
        {integrationsData &&
          integrationsData.source &&
          integrationsData.destination && (
            <IconWrapperStyled>
              <div>
                <LogoStyled
                  src={integrationsData.source.logo}
                  alt={integrationsData.source.name}
                  title={integrationsData.source.name}
                />
                <AiOutlineArrowRight />
                <LogoStyled
                  src={integrationsData.destination.logo}
                  alt={integrationsData.destination.name}
                  title={integrationsData.destination.name}
                />
              </div>
              <ProgressBar
                $progress={step === 0 ? 5 : step === 1 ? 50 : 100}
                $theme={'primary'}
              />
            </IconWrapperStyled>
          )}
      </HeaderStyled>
      <FastAppSurveyWrapperStyled>
        {isLoading ? (
          <LoaderWrapperStyled>
            <Loader $active $size={'large'} $text={loadingText} />
          </LoaderWrapperStyled>
        ) : (
          <Form onSubmitSuccess={onSubmitSuccess} />
        )}
      </FastAppSurveyWrapperStyled>
    </ContainerStyled>
  )
}
