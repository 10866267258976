import React from 'react'
import { FaCopy, FaMinus, FaPlus } from 'react-icons/fa'
import ReactTooltip, { Place, Type, Effect } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import {
  IconButtonStyled,
  IconsWrapperStyled,
} from '@components/Basic/FiltersIcons/styled'

interface Props {
  handleCopy: () => void
  handleAdd: () => void
  handleDelete: () => void
  isDeleteDisabled?: boolean
  isAddDisabled?: boolean
  isCopyDisabled?: boolean
  isBridgeDisabled?: boolean
}

const tooltipProps = {
  place: 'top' as Place,
  type: 'light' as Type,
  effect: 'solid' as Effect,
  border: true,
  borderColor: '#f6f7f8',
  className: 'reactTooltipFilterIcons',
}

export const FiltersIcons: React.FC<Props> = ({
  handleCopy,
  handleAdd,
  handleDelete,
  isDeleteDisabled = false,
  isAddDisabled = false,
  isCopyDisabled = false,
  isBridgeDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <div>
      <IconsWrapperStyled>
        <ReactTooltip id={'filter-copy'} {...tooltipProps}>
          {t('nbee.filters.tooltipIconCopy')}
        </ReactTooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isCopyDisabled || isBridgeDisabled}
          onClick={handleCopy}
          data-tip
          data-for='filter-copy'
        >
          <FaCopy size={15} />
        </IconButtonStyled>
        <ReactTooltip id={'filter-delete'} {...tooltipProps}>
          {t('nbee.filters.tooltipIconDelete')}
        </ReactTooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isDeleteDisabled || isBridgeDisabled}
          onClick={handleDelete}
          data-tip
          data-for='filter-delete'
        >
          <FaMinus size={15} />
        </IconButtonStyled>
        <ReactTooltip id={'filter-add'} {...tooltipProps}>
          {t('nbee.filters.tooltipIconAdd')}
        </ReactTooltip>
        <IconButtonStyled
          type={'button'}
          disabled={isAddDisabled || isBridgeDisabled}
          onClick={handleAdd}
          data-tip
          data-for='filter-add'
        >
          <FaPlus size={15} />
        </IconButtonStyled>
      </IconsWrapperStyled>
    </div>
  )
}
