import React, { Suspense } from 'react'
import { Loader } from '@components/Basic/Loader'

const ComponentLazy = React.lazy(() => import('./Router'))

export const GoogleAdsAccountRouter = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            position: 'relative',
            height: 200,
          }}
        >
          <Loader $active $dimmer $size={'large'} />
        </div>
      }
    >
      <ComponentLazy />
    </Suspense>
  )
}
