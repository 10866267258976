import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useChooseIntegrationSettings } from '@app/api/postChooseIntegrationSetting'
import { useFormikContext } from 'formik'
import {
  AppConfigurationType,
  BridgeFormValues,
  RetrivedSettingField,
} from 'Nbee'
import { ApiAppSetting } from 'BackendApi'
import { Skeleton } from '@components/Basic/Skeleton'
import { SettingFieldSmartSelect } from './SettingFieldSmartSelect'
import { SettingFieldInput } from './SettingFieldInput'
import { SettingFieldToggle } from './SettingFieldToggle'
import {
  fieldsForSmartSelect,
  fieldsForInput,
  fieldsForToggle,
  fieldsForSmartCreatableSelect,
  fillNewSettingsWithExistingValues, fieldsForGooglePicker
} from "./utils";
import { SettingFieldSmartCreatableSelect } from './SettingFieldSmartCreatableSelect'
import { useTranslation } from 'react-i18next'
import { useTriggerSettingsUpdate } from '@features/nbee/SimpleBridgeBuilderForm/fields/IntegrationSettings/useTriggerSettingsUpdate'
import { usePopupError } from './usePopupError'
import { GooglePicker } from "@components/ButtonProvider/Google/GooglePicker";

interface IntegrationSettingsProps {
  type: AppConfigurationType
  isValidIntegration?: boolean
  onCompletionChange?: (isComplete: boolean) => void
}

export const IntegrationSettings: React.FC<IntegrationSettingsProps> = ({
  type,
  isValidIntegration,
  onCompletionChange,
}) => {
  const { t } = useTranslation()
  const skipRequestNewSettingsToApi = useRef<boolean>()
  const [firstRun, setFirstRun] = useState(false)

  // some formik helpers
  const {
    values,
    setFieldValue,
    setSubmitting,
    setFieldTouched,
    validateForm,
  } = useFormikContext<BridgeFormValues>()
  const selectedIntegrationId = values[type].integrationId
  const selectedAppId = values[type].appId
  const settingFieldName = `${type}.settings`
  const currentSettingsValues = values[type].settings || []

  // chooseIntegrationSettings mutation
  const {
    data: integrationSettingsResponse,
    mutate: retrieveIntegrationSettings,
    isLoading: isLoadingIntegrationSettings,
    error: retrieveIntegrationSettingsApiError,
  } = useChooseIntegrationSettings()
  const [fieldsToRender, setFieldsToRender] = useState<ApiAppSetting[]>([])

  const retrieveUpdatedSettingFields = (
    integrationId: number,
    appId: number
  ) => {
    retrieveIntegrationSettings({
      integrationSettingsData: {
        integrationId,
        appId,
        type,
        settings: currentSettingsValues.map((setting) => ({
          key: setting.key,
          value: setting.value || '',
        })),
        refresh:
          (values.ui && values.ui[type] && values.ui[type]!.refresh) || [],
        bridgeId: values.ui?.bridgeId, // this is only when we are editing an existing bridge, for new bridges this will be undefined, so it will be ignored
      },
    })
  }

  // handling api error
  const { showPopupError, clearPopupError } = usePopupError({
    type,
    showLogo: true,
  })
  useEffect(() => {
    clearPopupError()
    if (retrieveIntegrationSettingsApiError) {
      showPopupError(retrieveIntegrationSettingsApiError)
    }
  }, [retrieveIntegrationSettingsApiError])

  // saving in local state
  useEffect(() => {
    const newSettingsReceived =
      integrationSettingsResponse?.data?.schemaSettings
    if (newSettingsReceived) {
      setFieldsToRender(newSettingsReceived)

      // we need to inform formik about the new settings received,  so it will be
      // able to proper handle touched states. To do this we merge existing
      // values into new settings data and we keep new settings with a default value.
      // This will also solve the case where a value was set but is not existing anymore
      // (example a filed that was depending from another value
      const existingSettings = values[type].settings || []
      const newSettingsWithDefaults = fillNewSettingsWithExistingValues(
        newSettingsReceived,
        existingSettings
      )

      // before updating form status we need to pause the re-fetching of new settings
      // and we set it back to normal once we are sure formik state has been updated
      // this is to prevent an endless loop, since api request to get new settings starts
      // is exectued every time formik state (settings field) changes
      skipRequestNewSettingsToApi.current = true
      setFieldValue(settingFieldName, newSettingsWithDefaults)
      // reset refresh array so we don't always request cache invalidation
      setFieldValue(`ui.${type}.refresh`, [])

      setTimeout(() => {
        skipRequestNewSettingsToApi.current = false
      }, 300)
    }
  }, [integrationSettingsResponse])

  // cleanup when selectedIntegrationId changes
  useEffect(() => {
    if (!selectedIntegrationId) {
      setFieldsToRender([])
      setFieldValue(settingFieldName, [])
      validateForm()
      setFieldTouched(settingFieldName, false)
      clearPopupError()
    }
  }, [selectedIntegrationId])

  // re-run mutation to get new settings fields when form settings values are updated
  const triggerSettingsUpdate =
    values.ui && values.ui[type] && values.ui[type]!.triggerSettingsUpdate
  useEffect(() => {
    // if (skipRequestNewSettingsToApi.current) {
    //   return
    // }

    if (
      selectedIntegrationId &&
      selectedAppId &&
      isValidIntegration &&
      !isLoadingIntegrationSettings
    ) {
      retrieveUpdatedSettingFields(selectedIntegrationId, selectedAppId)
    }
  }, [
    selectedIntegrationId,
    // currentSettingsValues,
    triggerSettingsUpdate,
    isValidIntegration,
  ])

  const { refetchSettings } = useTriggerSettingsUpdate(type)
  useEffect(() => {
    if (!firstRun && selectedIntegrationId) {
      setFirstRun(true)
      refetchSettings()
    }
  }, [currentSettingsValues, firstRun, selectedIntegrationId])

  useEffect(() => {
    setFirstRun(false)
  }, [selectedIntegrationId])

  // when retrieving new settings, we need to updated formik isSubmitting to disable continue button
  useEffect(() => {
    setSubmitting(isLoadingIntegrationSettings)
    setFieldValue(`ui.${type}.isLoadingApi`, isLoadingIntegrationSettings)
  }, [isLoadingIntegrationSettings])

  const canShowTitle = Boolean(
    selectedIntegrationId && isValidIntegration && fieldsToRender.length
  )

  const requiredFields: RetrivedSettingField[] = fieldsToRender.map(
    (field) => ({
      id: field.id,
      label: field.label,
      required: field.required || false,
      type: field.type,
      format: field.format,
    })
  )

  useEffect(() => {
    setFieldValue(`ui.${type}.allRetrivedSettingFields`, requiredFields)
  }, [fieldsToRender])

  return (
    <Fragment>
      {canShowTitle && (
        <h2>{t('nbee.bridgeBuilder.integrationSettingsTitle')}</h2>
      )}
      {fieldsToRender.map((field, idx) => {
        return field.hide ? null : (
          <Fragment key={field.id}>
            <div>
              {fieldsForSmartCreatableSelect.includes(field.type) ? (
                <SettingFieldSmartCreatableSelect
                  fieldSchema={field}
                  type={type}
                  index={idx}
                  isLoading={isLoadingIntegrationSettings}
                />
              ) : fieldsForGooglePicker.includes(field.type) ? (
                <GooglePicker
                  fieldSchema={field}
                  type={type}
                  index={idx}
                  isLoading={isLoadingIntegrationSettings}
                  apiHasError={!!retrieveIntegrationSettingsApiError}
                />
              ) : fieldsForSmartSelect.includes(field.type) ? (
                <SettingFieldSmartSelect
                  fieldSchema={field}
                  type={type}
                  index={idx}
                  isLoading={isLoadingIntegrationSettings}
                  apiHasError={!!retrieveIntegrationSettingsApiError}
                />
              ) : fieldsForInput.includes(field.type) ? (
                <SettingFieldInput
                  fieldSchema={field}
                  type={type}
                  index={idx}
                  isLoading={isLoadingIntegrationSettings}
                />
              ) : fieldsForToggle.includes(field.type) ? (
                <SettingFieldToggle
                  fieldSchema={field}
                  type={type}
                  index={idx}
                  isLoading={isLoadingIntegrationSettings}
                />
              ) : null}
            </div>
          </Fragment>
        )
      })}

      {/* Loading state (we need to keep existing fields visible */}
      {isLoadingIntegrationSettings ? (
        <div>
          <Skeleton forElement={'label'} />
          <Skeleton forElement={'input'} />
        </div>
      ) : null}
    </Fragment>
  )
}
