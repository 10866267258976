import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import NoResults from '@assets/images/illustrations/nbee-no-results.svg'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

interface Props {
  onResetFilterRequest: () => void
  isFiltered?: boolean
}

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 2rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`

const Text = styled.div`
  font-weight: 600;
`

const Image = styled.img`
  max-width: 100%;
  width: 200px;
`

export const FieldMappingEmptyState: React.VFC<Props> = ({
  onResetFilterRequest,
  isFiltered,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Text>
        {isFiltered
          ? t('nbee.fieldsMapping.noFieldsFoundForFilters')
          : t('nbee.fieldsMapping.noFieldsFound')}
      </Text>
      <Image src={NoResults} alt={t('nbee.fieldsMapping.noFieldsFound')} />
      {isFiltered ? (
        <Button
          type={'button'}
          $variant={'filter'}
          onClick={onResetFilterRequest}
        >
          {t('nbee.fieldsMapping.resetFilters')}
        </Button>
      ) : null}
    </Wrapper>
  )
}
