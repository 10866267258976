import React from 'react'
import { Modal } from '@components/Basic/Modal'
import { Button } from '@components/Basic/ButtonNbe'
import { ReactComponent as SuperHeroImg } from '@assets/images/superhero.svg'
import { Trans, useTranslation } from 'react-i18next'
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from '@features/nbee/ModalTrialInfo/styled'
import { appRoutes } from '@app/routes'

interface ModalProps {
  isOpen: boolean
  showSurpriseMessage?: boolean
  planName?: string
  onCloseModal: () => void
}

export const ModalTrialInfo: React.FC<ModalProps> = ({
  isOpen,
  onCloseModal,
  showSurpriseMessage,
  planName,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isWide
      isTransparent={false}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      hideCloseButton
    >
      <ModalWrapper>
        {showSurpriseMessage ? (
          <>
            <ModalHeader>
              &#127881; {t('nbee.trialInfoModal.fromFreeHeader')} &#127881;
            </ModalHeader>
            <ModalBody>
              <p>
                {t('nbee.trialInfoModal.fromFreeParagraph1', {
                  planName: planName,
                })}{' '}
                &#x1F680;
              </p>
              <p>{t('nbee.trialInfoModal.fromFreeParagraph2')}</p>
              <SuperHeroImg />
              <p>{t('nbee.trialInfoModal.fromFreeParagraph3')}</p>
              <p>
                <Trans
                  i18nKey={'nbee.trialInfoModal.fromFreeParagraph4'}
                  components={{
                    a: <a href={appRoutes.pricing.makeUrl()} />,
                  }}
                />
                &#x1F31F;
              </p>
              <p>{t('nbee.trialInfoModal.fromFreeParagraph5')} &#x1F91D;</p>
            </ModalBody>
          </>
        ) : (
          <>
            <ModalHeader>
              &#127881; {t('nbee.trialInfoModal.fromProHeader')} &#127881;
            </ModalHeader>
            <ModalBody>
              <p>{t('nbee.trialInfoModal.fromProParagraph1')} &#x1F680;</p>
              <p>{t('nbee.trialInfoModal.fromProParagraph2')}</p>
              <SuperHeroImg />
              <p>{t('nbee.trialInfoModal.fromProParagraph3')}</p>
              <p>
                <Trans
                  i18nKey={'nbee.trialInfoModal.fromProParagraph4'}
                  components={{
                    a: <a href={appRoutes.pricing.makeUrl()} />,
                  }}
                />
                &#x1F31F;
              </p>
              <p>{t('nbee.trialInfoModal.fromProParagraph5')} &#x1F91D;</p>
            </ModalBody>
          </>
        )}

        <ModalFooter>
          <Button
            onClick={onCloseModal}
            $fluid
            type={'button'}
            $variant={'primary'}
          >
            Continue to LeadsBridge
          </Button>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  )
}
