import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PageEditor } from '@app/layouts/PageEditor'
import { Panel } from '@components/Panel'
import { makeGoogleAccountSteps } from '@features/googleAdsAccount/utils'
import { PanelBody } from '@features/googleAdsAccount/AccountActivate/PanelBody'
import { Button } from '@components/Basic/ButtonNbe'
import { appRoutes } from '@app/routes'
import { useHistory } from 'react-router-dom'
import { trackEvent } from '@app/dataTracking'

interface AccountActivateProps {
  billingData: number
  handlePollingState: (state: boolean) => void
}
export const AccountActivate: React.FC<AccountActivateProps> = ({
  billingData,
  handlePollingState,
}) => {
  const { t } = useTranslation()
  const steps = makeGoogleAccountSteps(1)
  const history = useHistory()
  const customerId = window.localStorage.getItem('customerId') || ''

  useEffect(() => {
    if (billingData === 2) {
      handlePollingState(false)
      trackEvent({
        eventName: 'GaacBillingAdded',
        feature: 'GAAC',
        step: 'Activate',
        params: {
          custom: {
            customerId,
          },
        },
      })
      history.push(appRoutes.googleAdsAccountConnect.makeUrl())
    }
  }, [billingData])
  return (
    <PageEditor
      steps={steps}
      activeStep={1}
      topbarValue={t('gaac.topbarTitle')}
      hasTopBar
      backlink={{
        url: '/app/googleAdsAccounts',
        label: t('gaac.topbarExitButton'),
      }}
    >
      <Panel body={<PanelBody />} />
    </PageEditor>
  )
}
