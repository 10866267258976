import React from 'react'
import { LoaderStyled, LoaderDimmer } from './styled'

export interface LoaderProps {
  $active?: boolean
  $text?: string
  $size?: 'large' | 'small' | 'x-large'
  $inverted?: boolean
  $dimmer?: boolean
}

export const Loader: React.FC<LoaderProps> = ({ $text, $dimmer, ...props }) => {
  return $dimmer ? (
    <LoaderDimmer>
      <LoaderStyled {...props}>{$text}</LoaderStyled>
    </LoaderDimmer>
  ) : (
    <LoaderStyled {...props}>{$text}</LoaderStyled>
  )
}
