import React, { Fragment } from 'react'
import { PageEditor } from '@app/layouts/PageEditor'
import { Formik, Form, FormikHelpers } from 'formik'
import { Panel } from '@components/Panel'
import { FormInner } from './FormInner'
import { SignupSurveyFormValues } from 'SignupSurvey'
import { initialValues, makeValidationSchema } from './formUtils'
import { useTranslation } from 'react-i18next'
import { AppLogos, BbuData } from '@features/SignupSurvey/AppLogos'

interface Props {
  bbuData?: BbuData
  onSubmit: (
    values: SignupSurveyFormValues,
    formikHelpers: FormikHelpers<SignupSurveyFormValues>
  ) => void
}

export const SignupSurvey: React.VFC<Props> = ({ bbuData, onSubmit }) => {
  const { t } = useTranslation()
  const steps = [
    {
      title: t('auth.signupSurvey.stepName'),
      label: t('auth.signupSurvey.stepName'),
    },
    {},
    {},
  ]

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={makeValidationSchema(t)}
      onSubmit={(values, formikHelpers) => {
        onSubmit(values, formikHelpers)
      }}
    >
      {() => {
        return (
          <Fragment>
            <PageEditor steps={steps} activeStep={0} hasTopBar>
              <Form>
                <Panel
                  head={bbuData && <AppLogos bbuData={bbuData} />}
                  body={<FormInner />}
                />
              </Form>
            </PageEditor>
          </Fragment>
        )
      }}
    </Formik>
  )
}
