import React, { useEffect, useState } from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import { InputAutoSelectCopy } from '@components/InputAutoSelectCopy'
import { useTranslation } from 'react-i18next'
import { Title, Paragraph, Body } from './styled'
import { Loader } from '@components/Basic/Loader'
import { ButtonWrapper } from '@features/nbee/IncomingDataContent/IncomingDataFinalStep/styled'
import { useAppSelector } from '@app/store/hooks'

interface Props {
  appLogoUri?: string
  appId?: string
  appName: string
  listenerType: 'url' | 'email'
}

export const LoadingStep: React.FC<Props> = ({ appLogoUri, appName, listenerType }) => {
  const { t } = useTranslation()
  const integrationListener = useAppSelector(
    (state) => state.integrationListener
  )
  const [step, setStep] = useState(1)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (step < 3) {
        setStep((prevState) => prevState + 1)
      }
    }, 5000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [step])
  const loadingText =
    step === 1
      ? `incomingDataPopup.loadingText1`
      : step === 2
      ? `incomingDataPopup.loadingText2`
      : `incomingDataPopup.errorTitleWebhook`

  // If it's the second step, and appName is to be included, pass it to the translation function.
  const loadingTextTranslation =
    step === 2 ? t(loadingText, { appName }) : t(loadingText)

  return (
    <Body>
      <Title>
        <img src={appLogoUri} width='45' />
        <h2>{loadingTextTranslation}</h2>
      </Title>
      {step === 3 && (
        <>
          <Paragraph>
            {t(`incomingDataPopup.${listenerType}.errorParagraphWebhook`)}
          </Paragraph>
          <InputAutoSelectCopy
            label={t(`incomingDataPopup.${listenerType}.inputLabel`)}
            // docUrl={'https://leadsbridge.com'}
            linkKey={t('incomingDataPopup.linkTextFirstStepWebhook')}
            inputValue={integrationListener.data?.slug}
            tooltip={t(
              `incomingDataPopup.${listenerType}.inputCopyTooltipWebhook`,
              {
                appName: appName,
              }
            )}
          />
        </>
      )}
      <div style={{ position: 'relative', margin: '4rem 0 2rem' }}>
        <Loader
          $size={'large'}
          $text={step < 3 ? t('common.loading') : ''}
          $active={true}
        />
      </div>
      {step === 3 && (
        <ButtonWrapper>
          <Button
            style={{ width: '100%' }}
            $variant={'primary'}
            $size={'large'}
            onClick={() => {
              setStep(1)
            }}
          >
            {t('incomingDataPopup.ctaErrorReloadWebhook', {
              appName: appName,
            })}
          </Button>
          {/*  <div>
            <Button onClick={onDataReceived} $variant={'link-secondary'}>
              Simulate data received
            </Button>
          </div> */}
        </ButtonWrapper>
      )}
    </Body>
  )
}
