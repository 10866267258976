import React, { useEffect } from 'react'
import { useCheckAppsCompatibility } from '@app/api/checkAppsCompatibility'
import { LoadingStep } from '@features/nbee/LoadingStep'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { BridgeChooserStep2RouteParams } from 'LeadsBridgeApp'
import { appRoutes } from '@app/routes'

export const BridgeChooserRedirector: React.VFC = () => {
  const urlParams = useParams<BridgeChooserStep2RouteParams>()
  const history = useHistory()

  const sourceAppId = parseInt(urlParams.sourceAppId, 10)
  const destinationAppId = parseInt(urlParams.destinationAppId, 10)

  const {
    data: appCompatibilityCheck,
    isLoading: isCheckingAppsCompatibility,
  } = useCheckAppsCompatibility(sourceAppId, destinationAppId)

  const obeUri = appCompatibilityCheck?.combination.obeUri
  const nbeeUri = appCompatibilityCheck?.combination.nbeeUri

  useEffect(() => {
    if (obeUri && !nbeeUri) {
      window.location.href = obeUri
    } else if ((!obeUri && nbeeUri) || (obeUri && nbeeUri)) {
      // if we only have nbeeUri, or if we both have obeUri and nbeeUri,
      // we want to redirect the user directly to NBEE and never display the chooser.
      history.push(nbeeUri)
    }
  }, [obeUri, nbeeUri])

  if (appCompatibilityCheck?.combination.compatibility === 'none') {
    return <Redirect to={appRoutes.nbeeBridgeChooserStep1.makeUrl()} />
  }

  return isCheckingAppsCompatibility || !nbeeUri ? <LoadingStep /> : null
}
