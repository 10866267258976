import React, { ButtonHTMLAttributes } from 'react'
import {
  ButtonLoadingHiddenChildren,
  ButtonLoadingSpinner,
  ButtonLoadingWrapper,
  ButtonStyled,
} from './styled'

type ButtonStyle =
  | 'primary'
  | 'action'
  | 'secondary'
  | 'blue'
  | 'outlined-primary'
  | 'outlined-secondary'
  | 'filter'
  | 'link-primary'
  | 'link-secondary'
type ButtonSize = 'small' | 'standard' | 'large'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  $fluid?: boolean
  $loading?: boolean
  $loadingText?: string
  $variant?: ButtonStyle
  $size?: ButtonSize
  // when has an icon inside and needs to be aligned and separated a bit from text
  $hasIcon?: boolean
  disabled?: boolean
  $minWidth?: boolean
}

export const Button: React.FC<ButtonProps> = ({
  $variant,
  $size = 'standard',
  $hasIcon,
  $loading,
  $loadingText,
  $minWidth,
  children,
  ...props
}) => {
  // when there's a loading text we force a min width
  const hasMinWidth = $minWidth || Boolean($loadingText)
  return (
    <ButtonStyled
      $variant={$variant}
      $size={$size}
      $hasIcon={$hasIcon}
      $minWidth={hasMinWidth}
      disabled={props.disabled}
      {...props}
    >
      {$loading ? (
        <>
          <ButtonLoadingWrapper>
            <ButtonLoadingSpinner /> {$loadingText}
          </ButtonLoadingWrapper>
          {/* this is only used to keep a fixed button width */}
          <ButtonLoadingHiddenChildren>{children}</ButtonLoadingHiddenChildren>
        </>
      ) : (
        children
      )}
    </ButtonStyled>
  )
}
