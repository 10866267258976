import * as React from 'react'
import * as ReactDOM from 'react-dom'
import App from './App'
import { Router } from 'react-router-dom'
import store from './store'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import ToastComponent from './components/ToastComponent'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createServerInit } from './mockapi'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from '@app/styles/theme/default'
import './i18n'
import { AlertComponent } from '@components/Alert'
import { ErrorBoundary } from '@sentry/react'
import { ErrorPage } from '@components/ErrorBoundary/ErrorPage'
import { initSentry } from '@app/utils/sentry'

if (process.env.REACT_USE_MOCK_API === 'true') {
  createServerInit()
}

const history = createBrowserHistory()
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

initSentry()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={defaultTheme}>
          <ErrorBoundary fallback={() => <ErrorPage />}>
            <App />
          </ErrorBoundary>
          <ToastComponent />
          <AlertComponent />
        </ThemeProvider>
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
)
