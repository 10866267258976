import React from 'react'
import { Button } from '@components/Basic/ButtonNbe'
import {
  Wrapper,
  PlanType,
  PlanName,
  PriceStyled,
} from '@features/pricing/PlanInfoBanner/styled'
import { ApiFeatures } from 'BackendApi'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { getPlanNameToShow } from '@features/pricing/PricingCard'
import { InputSelect, InputSelectOption } from '@components/Form/InputSelect'
import { IoIosHelpCircleOutline } from 'react-icons/io'
import { InputWrapperIcon } from '@features/pricing/PricingCardControllers/styled'

interface Props {
  plan: ApiFeatures
  optionsMtu?: InputSelectOption[]
  optionsBridge?: InputSelectOption[]
  isCurrentPlan?: boolean
  onPlanSelect?: () => void
  isDisabled?: boolean
}

export const PlanInfoBanner: React.FC<Props> = ({
  plan,
  optionsMtu,
  optionsBridge,
  isCurrentPlan,
  onPlanSelect,
  isDisabled,
}) => {
  const { t } = useTranslation()

  const isBusiness = plan?.name?.toLowerCase() === 'business'
  const price = isBusiness ? '999' : '0'

  if (!plan) {
    return null
  }

  return (
    <Wrapper>
      <div>
        <PlanType>{plan?.serviceType}</PlanType>
        <PlanName>{getPlanNameToShow(plan?.name.toLowerCase())}</PlanName>
        <p>{plan?.description}</p>
      </div>
      {optionsMtu && optionsBridge ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <InputWrapperIcon>
            <InputSelect
              disabled
              name={plan.name}
              $options={optionsMtu}
              value={optionsMtu[0].value}
            />
            <IoIosHelpCircleOutline
              size={20}
              data-tip={t('billing.pricing.freemiumMtuTooltip')}
              data-for={'freemium-mtu-limit'}
              data-class='tooltip-style'
            />
            <ReactTooltip id={'freemium-mtu-limit'} />
          </InputWrapperIcon>
          <InputWrapperIcon>
            <InputSelect
              disabled
              name={plan.name}
              $options={optionsBridge}
              value={optionsBridge[0].value}
            />
            <IoIosHelpCircleOutline
              size={20}
              data-tip={t('billing.pricing.freemiumBridgeTooltip')}
              data-for={'freemium-bridge-limit'}
              data-class='tooltip-style'
            />
            <ReactTooltip id={'freemium-bridge-limit'} />
          </InputWrapperIcon>
        </div>
      ) : null}

      <div>
        <ul>
          {plan?.mainFeatures.map((feature, index) =>
            feature.name ? (
              <li key={index}>
                {feature.description ? (
                  <>
                    <ReactTooltip
                      className={'feature-item-styled'}
                      clickable={true}
                      place='right'
                      effect='solid'
                      html={true}
                      id={`${plan?.name}_feature_${index}`}
                    />
                    <span
                      className={'feature-item-label-styled'}
                      style={{ cursor: 'help' }}
                      data-tip={feature.description}
                      data-for={`${plan?.name}_feature_${index}`}
                    >
                      {feature.name}
                    </span>
                  </>
                ) : (
                  <>{feature.name}</>
                )}
              </li>
            ) : null
          )}
        </ul>
      </div>
      <div style={{ textAlign: 'center' }}>
        <PriceStyled>
          <h2>
            <span>$</span>
            {price}
          </h2>
        </PriceStyled>
        {isBusiness ? (
          <p>{t('billing.pricing.businessPriceDescription')}</p>
        ) : (
          <p>{t('billing.pricing.freemiumPriceDescription')}</p>
        )}
        <Button
          onClick={!isBusiness ? onPlanSelect : undefined}
          disabled={isCurrentPlan || isDisabled}
          $fluid
          $size={'large'}
          $variant={'primary'}
        >
          {isBusiness
            ? t('billing.pricing.businessCta')
            : isCurrentPlan
            ? t('billing.pricing.ctaCurrentPlan')
            : t('billing.pricing.ctaChoosePlan')}
        </Button>
      </div>
    </Wrapper>
  )
}
