import React from 'react'
import { LabelStyled } from './styled'

export interface LabelProps {
  $disabled?: boolean
  $label?: string
  htmlFor?: string
  allowsHtml?: boolean
}

export const Label: React.FC<LabelProps> = ({
  $disabled,
  htmlFor,
  $label,
  allowsHtml,
}) => {
  const labelId = `${htmlFor}`
  return allowsHtml ? (
    <LabelStyled
      $disabled={$disabled}
      htmlFor={labelId}
      dangerouslySetInnerHTML={{
        __html: $label || '',
      }}
    />
  ) : (
    <LabelStyled $disabled={$disabled} htmlFor={labelId}>
      {$label}
    </LabelStyled>
  )
}
