import React, { useEffect } from 'react'
import { HeaderSearchComponent } from './HeaderSearchComponent'
import { useFormikContext } from 'formik'
import { App, BridgeFormValues } from 'Nbee'
import {
  isFieldMapped,
  isFieldUnmapped,
} from '@features/nbee/FieldsMappingForm/utils'
import { WelcomeMessageProps } from '@features/nbee/FieldsMappingForm'

interface Props {
  sourceAppId?: number
  destinationAppId?: number
  totalFields: number
  mappedFields: number
  inputValue: string
  onInputChange: (value: string) => void
  showAllFields: boolean
  onShowAllFieldsRequest: (showAllFields: boolean) => void
  isInTestScreen?: boolean
  isReloadingAllBridgeFields: boolean
  onRefetchBridgeFieldRequest: () => void
  maxFields?: number
  welcomeMessageProps?: WelcomeMessageProps
  connectedApps: App[] | undefined
}

export const HeaderSearch: React.FC<Props> = ({
  sourceAppId,
  destinationAppId,
  totalFields,
  inputValue,
  mappedFields,
  onInputChange,
  showAllFields,
  onShowAllFieldsRequest,
  isReloadingAllBridgeFields,
  onRefetchBridgeFieldRequest,
  isInTestScreen,
  maxFields,
  welcomeMessageProps,
  connectedApps,
}) => {
  const sourceApp = (connectedApps || []).find((app) => app.id === sourceAppId)
  const destinationApp = (connectedApps || []).find(
    (app) => app.id === destinationAppId
  )

  const defaultTransparentPixel = `data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==`

  const sourceName = sourceApp?.name || ''
  const sourceLogoUri = sourceApp?.logoUriSmall || defaultTransparentPixel
  const destinationName = destinationApp?.name || ''
  const destinationLogoUri =
    destinationApp?.logoUriSmall || defaultTransparentPixel

  const { values, setFieldValue } = useFormikContext<BridgeFormValues>()
  // setting app names, it will be useful in case we need to access in other parts of the form (eg. tracking events)
  useEffect(() => {
    if (sourceName) {
      setFieldValue('ui.source.selectedAppName', sourceName)
    }
    if (destinationName) {
      setFieldValue('ui.destination.selectedAppName', destinationName)
    }
  }, [sourceName, destinationName])

  // show button logic
  const fieldsMappingValues = values.fieldsMapping || []

  const allAreMapped =
    fieldsMappingValues && fieldsMappingValues.length
      ? fieldsMappingValues.every(isFieldMapped)
      : false

  const allAreUnmapped =
    fieldsMappingValues && fieldsMappingValues.length
      ? fieldsMappingValues.every(isFieldUnmapped)
      : false

  // we show the button only when user is not typing in the search box or when there is something to toggle
  // when all fields are mapped or all is unmapped there is nothing to toggle
  const thereIsNothingToToggle = allAreMapped || allAreUnmapped
  const showFilterButton = inputValue ? false : !thereIsNothingToToggle

  useEffect(() => {
    // when we hide the button we need to be sure to show all fields
    if (!showFilterButton) {
      onShowAllFieldsRequest(true)
    }
  }, [showFilterButton])

  return (
    <HeaderSearchComponent
      totalFields={totalFields}
      mappedFields={mappedFields}
      sourceName={sourceName}
      sourceLogoUri={sourceLogoUri}
      destinationName={destinationName}
      destinationLogoUri={destinationLogoUri}
      onInputChange={onInputChange}
      inputValue={inputValue}
      showAllFields={showAllFields}
      onShowAllFieldsRequest={onShowAllFieldsRequest}
      isInTestScreen={isInTestScreen}
      showFilterButton={showFilterButton}
      isReloadingAllBridgeFields={isReloadingAllBridgeFields}
      onRefetchFieldRequest={onRefetchBridgeFieldRequest}
      isReadonlyModeEnabled={values.ui?.isBridgeEnabled}
      maxFields={maxFields}
      welcomeMessageProps={welcomeMessageProps}
    />
  )
}
