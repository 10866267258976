import React, { useState } from 'react'
import {
  IntegrationPanelStyled,
  IntegrationPanelBody,
  IntegrationPanelHead,
  IntegrationPanelWrapper,
  PanelHeadLeft,
  PanelHeadRight,
  PanelHeadTitle,
  IntegrationPanelFooter,
  ButtonGroup,
  PanelHeadRightLink,
} from './styled'
import { Button } from '@components/Basic/ButtonNbe'
import { FaChevronUp } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
import { Checkbox } from '@components/Form/Checkbox'

export interface IntegrationPanelBodyProps {
  $open: boolean
}
interface Props {
  integrationLogo: string
  panelTitle: string
  isTosAccepted?: boolean
  btnMoreUrl?: string
  panelDescription?: string
  btnPrimaryLabel?: string
  btnPrimaryLink?: string
  isbtnPrimaryAccordion?: boolean
  btnLinkLabel?: string
  btnLinkUrl?: string
  accordionButtonLabel?: string
  accordionButtonUrl?: string
  closeTooltipText?: string
  handleAction?: (status: string) => void
  children?: React.ReactNode
  testIdPrimaryButton?: string
  testIdLinkButton?: string
  testIdPolicyButton?: string
  accordionCheckboxLabel?: string
}
export const IntegrationPanel: React.FC<Props> = ({
  integrationLogo,
  isTosAccepted,
  panelTitle,
  panelDescription,
  btnPrimaryLabel,
  btnPrimaryLink,
  isbtnPrimaryAccordion,
  btnLinkLabel,
  btnLinkUrl,
  accordionButtonLabel,
  accordionButtonUrl,
  closeTooltipText,
  handleAction,
  children,
  testIdPrimaryButton,
  testIdLinkButton,
  testIdPolicyButton,
  accordionCheckboxLabel,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <IntegrationPanelStyled>
      <IntegrationPanelWrapper>
        <IntegrationPanelHead>
          <PanelHeadLeft>
            <div>
              <img src={integrationLogo} width={30} />
              {panelTitle ? (
                <PanelHeadTitle>{panelTitle}</PanelHeadTitle>
              ) : null}
            </div>
            {panelDescription ? <div>{panelDescription}</div> : null}
          </PanelHeadLeft>
          <PanelHeadRight>
            {isbtnPrimaryAccordion && btnPrimaryLabel ? (
              <ButtonGroup>
                <ReactTooltip effect='solid' id='panel-integration-chevron'>
                  {closeTooltipText}
                </ReactTooltip>
                {open ? (
                  <div style={{ textAlign: 'center' }}>
                    <FaChevronUp
                      data-tip=''
                      data-for='panel-integration-chevron'
                      data-place='top'
                      onClick={() => setOpen(!open)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ) : (
                  <Button
                    $variant={'primary'}
                    onClick={() => {
                      setOpen(!open)
                    }}
                    disabled={open}
                  >
                    {btnPrimaryLabel}
                  </Button>
                )}
              </ButtonGroup>
            ) : btnPrimaryLink && btnPrimaryLabel ? (
              <a rel='noreferrer' target={'_blank'} href={btnPrimaryLink}>
                <Button data-testid={testIdPrimaryButton} $variant={'primary'}>
                  {btnPrimaryLabel}
                </Button>
              </a>
            ) : null}
            <PanelHeadRightLink
              data-testid={testIdLinkButton}
              target={'_blank'}
              href={btnLinkUrl}
              rel='noreferrer'
            >
              {btnLinkLabel}
            </PanelHeadRightLink>
          </PanelHeadRight>
        </IntegrationPanelHead>
        {isbtnPrimaryAccordion ? (
          <IntegrationPanelBody $open={open}>
            <IntegrationPanelFooter>
              <Checkbox
                data-testid={testIdPolicyButton}
                name={'tos'}
                onChange={() => {
                  if (handleAction) {
                    handleAction(status)
                  }
                }}
                checked={isTosAccepted}
              >
                {accordionCheckboxLabel}
              </Checkbox>

              {accordionButtonLabel ? (
                <a target={'_blank'} rel='noreferrer' href={accordionButtonUrl}>
                  <Button
                    data-testid={testIdPrimaryButton}
                    disabled={!isTosAccepted}
                    $variant={'primary'}
                  >
                    {accordionButtonLabel}
                  </Button>
                </a>
              ) : null}
            </IntegrationPanelFooter>
          </IntegrationPanelBody>
        ) : null}
      </IntegrationPanelWrapper>
      {children}
    </IntegrationPanelStyled>
  )
}
