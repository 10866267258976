import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { appRoutes } from '@app/routes'
import { IncomingDataScreen } from './IncomingDataScreen'
import { IncomingDataScreenSave } from './IncomingDataScreenSave'
import { IncomingDataScreenFields } from './IncomingDataScreenFields'

export const IncomingDataRouter: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={appRoutes.incomingDataScreen.path}
        component={IncomingDataScreen}
      />
      <Route
        exact
        path={appRoutes.incomingDataScreenEdit.path}
        component={IncomingDataScreen}
      />
      <Route
        exact
        path={appRoutes.incomingDataScreenFields.path}
        component={IncomingDataScreenFields}
      />
      <Route
        exact
        path={appRoutes.incomingDataScreenSave.path}
        component={IncomingDataScreenSave}
      />
    </Switch>
  )
}
