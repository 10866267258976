import React from 'react'
import styled from 'styled-components'

const SimplePageWrapper = styled.div`
  background-color: rgba(65, 166, 220, 0.15);
  min-height: 100vh;
  padding: 1rem;

  @media (min-width: 980px) {
    padding: 2.5rem;
  }
`

const CardWrapper = styled.div`
  background-color: white;
  padding: 2em;
`

export const SimplePage: React.FC = ({ children }) => {
  return (
    <SimplePageWrapper>
      <CardWrapper>{children}</CardWrapper>
    </SimplePageWrapper>
  )
}
