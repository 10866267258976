import React, { useState, useEffect } from 'react'
import { Modal } from '@components/Basic/Modal'
import { SelectValue } from '@components/Form/InputSmartSelect'
import { Button } from '@components/Basic/ButtonNbe'
import {
  ApiBridgeFieldDefinition,
  ApiUserModuleItem,
  ApiBridgeFieldDefinitionType,
} from 'BackendApi'
import { BridgeFilterCondition } from 'Nbee'
import { usePostSaveBridgeFilterRules } from '@app/api/postSaveBridgeFilterRules'
import { Loader } from '@components/Basic/Loader'
import { FilterRuleRow } from '@features/nbee/AddFilterButton/AddFilterButtonModal/FilterRuleRow'
import { Panel } from '@components/Panel'
import { Trans, useTranslation } from 'react-i18next'
import { useGetFiltersSchema } from '@app/api/getFiltersSchema'
import { useDeleteBridgeFilterRules } from '@app/api/deleteBridgeFilterRules'
import { IoCloseOutline } from 'react-icons/io5'
import {
  FilterModalBody,
  FilterModalCloseIcon,
  FilterModalFooter,
  FilterModalHeader,
  FilterModalWrapper,
} from '@features/nbee/AddFilterButton/styled'

import { FilterNotAvailablePage } from './FilterNotAvailablePage'
import { apiBridgeFieldTypes } from '@app/@typings/Api/bridgeFieldsArrayTypes'

interface ModalProps {
  isOpen: boolean
  onCloseModal: () => void
  sourceFields?: ApiBridgeFieldDefinition[] | []
  initialFilterRules?: BridgeFilterCondition[] | []
  modalTitle: string
  bridgeId: number
  onSaveOrDeleteFilterRules: (filterRules: BridgeFilterCondition[]) => void
  filterUserModules: ApiUserModuleItem | undefined
  isBridgeDisabled: boolean
}

const emptyFilterRule: BridgeFilterCondition = {
  type: '',
  values: '',
  operator: '',
  field: {
    id: '-1',
    label: '',
  },
}
export const AddFilterButtonModal: React.FC<ModalProps> = ({
  isOpen,
  onCloseModal,
  sourceFields,
  initialFilterRules,
  modalTitle,
  bridgeId,
  onSaveOrDeleteFilterRules,
  filterUserModules,
  isBridgeDisabled,
}) => {
  const { t } = useTranslation()
  // Retrieve filter schema
  const { data: filtersSchema, isLoading: isGettingFiltersSchema } =
    useGetFiltersSchema()
  const [allInputsEmpty, setAllInputsEmpty] = useState(false)

  // Save Filter
  const {
    mutate: saveBridgeFilterRules,
    isLoading: isSavingBridgeFilterRules,
    data: saveBridgeFilterRulesResult,
  } = usePostSaveBridgeFilterRules()

  const {
    mutate: deleteBridgeFilterRules,
    isLoading: isDeletingBridgeFilterRules,
    data: deleteBridgeFilterRulesResult,
    status: deleteBridgeFilterRulesStatus,
  } = useDeleteBridgeFilterRules()

  const userHasFiltersEnabled = filterUserModules?.enabled
  const minimumPlanRequired = filterUserModules?.minimumPlan

  // This state will contain the filter rules and will bind with the form displayed on screen.
  // The content of this state will also reflect the expected payload by BE.
  const [filterRules, setFilterRules] = useState<BridgeFilterCondition[]>([
    emptyFilterRule,
  ])

  // Loads existing filter rules into state, if any, when the component renders for the first time.
  useEffect(() => {
    if (initialFilterRules?.length) {
      setFilterRules(initialFilterRules)
    }
  }, [])

  // If there are no rules or if all rules were deleted, displays an empty rule.
  useEffect(() => {
    // Debugging console logs
    // console.log('filtersSchema', filtersSchema)
    // console.log('sourceFields', sourceFields)
    // console.log('initialFilterRules', initialFilterRules)
    // console.log('filterRules', filterRules)

    if (filterRules.length === 0) {
      setFilterRules([emptyFilterRule])
    }

    if (filterRules.length === 1 && filterRules[0].field.id === '-1') {
      setAllInputsEmpty(true)
    } else setAllInputsEmpty(false)
  }, [filterRules])

  // Adds a new rule
  const handleAddFilterRule = (idx: number) => {
    const newIndex = Math.min(
      idx !== undefined ? idx + 1 : filterRules.length,
      filterRules.length
    )
    const newFilterRules = [...filterRules]
    newFilterRules.splice(newIndex, 0, emptyFilterRule)
    setFilterRules(newFilterRules)
  }

  // Removes a rule
  const handleRemoveFilterRule = (idx: number) => {
    setFilterRules([
      ...filterRules.slice(0, idx),
      ...filterRules.slice(idx + 1),
    ])
  }

  // Deletes all rules
  const handleDeleteAllRules = () => {
    setFilterRules([])
  }

  // Copies an existing rule
  const handleCopyFilterRule = (idx: number) => {
    const newIndex =
      idx !== undefined && idx < filterRules.length
        ? idx + 1
        : filterRules.length
    const newFilterRules = [...filterRules]
    newFilterRules.splice(newIndex, 0, filterRules[idx])
    setFilterRules(newFilterRules)
  }

  // Triggers on the onSelect event for the first select (select field)

  /* `ApiBridgeFieldDefinitionType` is a TypeScript type and, unfortunately, there's no direct way to retrieve the values from a union type.
  Therefore, we need to declare the valid types manually in an array "apiBridgeFieldTypes".
  We then check if `selectedField?.type` is one of the valid types.
  If it is, we use `selectedField.type`, else we default to 'text'.
  */
  const handleFieldChange = (idx: number, { label }: SelectValue) => {
    const selectedField = sourceFields?.find((f) => f.label === label)

    const selectedFieldType = selectedField?.type
      ? apiBridgeFieldTypes.includes(
          selectedField.type as ApiBridgeFieldDefinitionType
        )
        ? selectedField.type
        : 'text'
      : ''

    setFilterRules((prevRules) => {
      const newRules = [...prevRules]
      newRules[idx] = {
        ...newRules[idx],
        field: {
          label: selectedField?.label || '',
          id: selectedField?.id || '-1',
        },
        type: selectedFieldType || 'text',
        operator: '',
        values: '',
      }
      return newRules
    })
  }

  // Triggers on the onSelect event for the second select (select operator)
  const handleOperatorChange = (idx: number, operator: string) => {
    const newRules = [...filterRules]
    newRules[idx] = { ...newRules[idx], operator: `${operator}`, values: '' }
    setFilterRules(newRules)
  }

  // Triggers on the change of input value for the third input (in case of text operators)
  const handleValueChange = (idx: number, value: string | string[] | []) => {
    setFilterRules((prevRules) => {
      const newRules = [...prevRules]
      newRules[idx] = {
        ...newRules[idx],
        values: value,
      }
      return newRules
    })
  }

  // Obtains the default value to display in the first select (select field) OR in the second select (select operator)
  const getDefaultSelectOption = (
    label: string,
    value: string,
    fieldId: string
  ) => {
    if (fieldId !== '-1') {
      return {
        label: `${label}`,
        value: `${value}`,
      }
    } else {
      return []
    }
  }

  // Obtains list of possible options for first select (select field)
  const getFieldsForSelect = (fields: ApiBridgeFieldDefinition[]) => {
    return (
      fields?.map((field) => ({
        value: field.label,
        label: field.label,
      })) || []
    )
  }

  // Obtains list of possible options for second select (select operator)
  const getOperatorsForSelect = (fieldType: string) => {
    const schemaEntry = filtersSchema?.data
      .find((entry) => entry.key === fieldType)
      ?.rules.map((operator) => {
        return {
          value: operator.type,
          label: operator.type
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b(\w)/g, (char) => char.toUpperCase()),
        }
      })
    if (!schemaEntry) {
      return []
    } else {
      return schemaEntry
    }
  }

  // Obtains the type of input to use depending on the selected operator
  const getInputTypeForCondition = (rule: BridgeFilterCondition) => {
    return filtersSchema?.data
      .find((entry) => entry.key === rule.type)
      ?.rules.find((entry) => entry.type === rule.operator)?.value
  }

  // Determines whether there are rules with empty input fields (and the operator value is not 'disabled')
  const hasEmptyFilterRules: boolean = filterRules.some((rule) =>
    rule.field.id === '-1'
      ? false
      : rule.field.label === '' ||
        rule.operator === '' ||
        (filtersSchema?.data
          .find((entry) => entry.key === rule.type)
          ?.rules.find(
            (operatorRule) =>
              operatorRule.type === rule.operator &&
              operatorRule.value !== 'disabled'
          ) &&
          rule.values === '')
  )

  // Determines if there are two rules with the same field and the same operator
  /* const hasDuplicates =
    filterRules.length !==
    filterRules.filter((rule, index, self) => {
      const isUnique: boolean =
        self.findIndex(
          (r) => r.field.id === rule.field.id && r.operator === rule.operator
        ) === index
      return isUnique
    }).length */

  // Determines whether the submit button should be disabled
  const isSubmitButtonDisabled: boolean =
    hasEmptyFilterRules ||
    /* hasDuplicates || */
    isDeletingBridgeFilterRules ||
    isSavingBridgeFilterRules ||
    isGettingFiltersSchema ||
    isBridgeDisabled

  // Submits payload to API
  const handleSubmitSaveFilters = () => {
    // just close the modal if there are no existing nor new rules
    if (allInputsEmpty && !initialFilterRules?.length) {
      onCloseModal()
      return
    }
    // if there are existing rules, but all inputs are empty, delete all rules
    if (allInputsEmpty) {
      deleteBridgeFilterRules({ bridgeId })
    } else {
      // otherwise, save the rules
      const requestBody = {
        conditions: [...filterRules].filter(
          (el) => el.field.id !== '-1' // filters out rules that have empty field
        ),
        sourceFields: [...(sourceFields || [])],
      }
      saveBridgeFilterRules({ bridgeId, requestBody })
    }
  }

  useEffect(() => {
    if (
      saveBridgeFilterRulesResult ||
      deleteBridgeFilterRulesStatus === 'success'
    ) {
      const newFilterRules =
        saveBridgeFilterRulesResult?.data?.sourceFilters?.conditions || []
      onSaveOrDeleteFilterRules(newFilterRules)
      onCloseModal()
    }
  }, [saveBridgeFilterRulesResult, deleteBridgeFilterRulesResult])

  return (
    <Modal
      noPadding
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      isTransparent={false}
      hideCloseButton={false}
    >
      {isGettingFiltersSchema ||
        isSavingBridgeFilterRules ||
        (isDeletingBridgeFilterRules && <Loader $active />)}

      <FilterModalWrapper>
        <FilterModalHeader>
          <h3>{modalTitle}</h3>
          <FilterModalCloseIcon onClick={onCloseModal}>
            <IoCloseOutline />
          </FilterModalCloseIcon>
        </FilterModalHeader>
        {(userHasFiltersEnabled && (
          <p style={{ padding: '1rem 1.5rem 1.2rem 1.5rem', margin: 0 }}>
            <Trans
              ns={'all'}
              i18nKey={'nbee.filters.modalFiltersSubtitle'}
              components={{
                strong: <strong />,
              }}
            />
          </p>
        )) ||
          null}
        <FilterModalBody>
          {/* {userHasFiltersEnabled ? ( */}
          <>
            {filterRules &&
              filterRules.length > 0 &&
              filterRules.map((filterRule, index) => (
                <FilterRuleRow
                  key={`${index}|${filterRule.operator}|${filterRule.field.id}`}
                  index={index}
                  filterRule={filterRule}
                  isDefaultValueNotInSourceAnymore={
                    !sourceFields?.find(
                      (el) => el.label === filterRule.field?.label
                    )
                  }
                  fieldSelectDefaultValue={getDefaultSelectOption(
                    filterRule.field?.label,
                    filterRule.field?.label,
                    filterRule.field?.id
                  )}
                  fieldSelectInitialValues={getFieldsForSelect(
                    sourceFields ?? []
                  )}
                  onFieldChange={handleFieldChange}
                  operatorSelectDefaultValue={getDefaultSelectOption(
                    filterRule.operator
                      .toLowerCase()
                      .replace(/_/g, ' ')
                      .replace(/\b(\w)/g, (char) => char.toUpperCase()),
                    filterRule.operator,
                    filterRule.field?.id
                  )}
                  operatorSelectInitialValues={getOperatorsForSelect(
                    filterRule.type || 'text' // default value for field type should be 'text'
                  )}
                  onOperatorChange={handleOperatorChange}
                  inputValueType={getInputTypeForCondition(filterRule)}
                  onValueChange={handleValueChange}
                  onCopyFilterRule={handleCopyFilterRule}
                  onAddFilterRule={handleAddFilterRule}
                  onRemoveFilterRule={handleRemoveFilterRule}
                  isRemoveFilterRuleDisabled={filterRules.length === 1}
                  isBridgeDisabled={isBridgeDisabled}
                />
              ))}
          </>
          {/*  ) : (
            <FilterNotAvailablePage minimumPlanRequired={minimumPlanRequired} />
          )} */}
        </FilterModalBody>
        <FilterModalFooter>
          <Button
            onClick={() => handleDeleteAllRules()}
            type={'button'}
            $variant={'link-primary'}
            $size={'large'}
            data-testid='delete-all-rules-button'
            disabled={isBridgeDisabled}
          >
            Delete all rules
          </Button>
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Button
              data-testid='cancel-filter-button'
              type={'button'}
              $variant={'secondary'}
              onClick={onCloseModal}
            >
              {'Cancel'}
            </Button>

            <Button
              data-testid='save-filter-button'
              type={'button'}
              $variant={'primary'}
              onClick={() => handleSubmitSaveFilters()}
              disabled={isSubmitButtonDisabled}
              data-tip
              data-for={'filter-disabled-button'}
            >
              {'Save Changes'}
            </Button>
          </div>
        </FilterModalFooter>
      </FilterModalWrapper>
    </Modal>
  )
}
