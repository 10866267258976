import { combineReducers } from 'redux'
import { ApplicationConfigurationReducer } from './reducers/ApplicationConfigurationReducer'
import { FbAppStoreReducer } from './reducers/FbAppStoreReducer'
import { UserReducer } from './reducers/UserReducer'
import { configureStore } from '@reduxjs/toolkit'
import { IntegrationListenerReducer } from './reducers/IntegrationListenerReducer'

const rootReducer = combineReducers({
  application: ApplicationConfigurationReducer,
  appStore: FbAppStoreReducer,
  user: UserReducer,
  integrationListener: IntegrationListenerReducer,
})

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these field paths to handle buttons ReactNode in Alert component
        ignoredActionPaths: ['payload.buttons'],
        ignoredPaths: ['application.alert'],
      },
    }),
})

export default store
