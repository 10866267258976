import React from 'react'
import { useFormikContext } from 'formik'
import { BridgeFormValues, EmailReceiptSettings } from 'Nbee'
import { emailReceiptValidationSchema } from '@features/nbee/EmailReceipt/validation'
import { FormInner } from '@features/nbee/EmailReceipt/FormInner'
import { FeatureNotAvailable } from '@features/nbee/EmailReceipt/FeatureNotAvailable'
import { minimumPlanName } from '@features/nbee/FieldsMappingForm'
import { StarterLabel } from '@features/nbee/AddFilterButton/styled'
import { FaLock } from 'react-icons/fa'
import { EmailReceiptStyled } from '@features/nbee/EmailReceipt/styled'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'
import { ApiUserModuleItem, ApiUser } from 'BackendApi'

interface Props {
  bridgeId?: string | number
  emailReceiptUserModule?: ApiUserModuleItem
  userData?: ApiUser
}

export const EmailReceipt: React.VFC<Props> = ({
  bridgeId,
  emailReceiptUserModule,
  userData,
}) => {
  const { values, setValues, isValid, isSubmitting, errors } =
    useFormikContext<BridgeFormValues>()

  const isBridgeEnabled = values?.ui?.isBridgeEnabled
  const { TitleWrapper, IconLockWrapper } = EmailReceiptStyled

  const moduleReceiptId = emailReceiptUserModule?.id

  const userEmail = userData?.user.email ? [userData?.user.email] : []

  const isAvailableToUser = Boolean(emailReceiptUserModule?.enabled)
  const minimumPlanRequired = emailReceiptUserModule?.minimumPlan

  const updateBridgeToPricing = useUpdateBridgeToPricing(
    bridgeId,
    moduleReceiptId,
    minimumPlanRequired
  )

  const planLabelName = minimumPlanRequired
    ? minimumPlanName[minimumPlanRequired.toString()].toUpperCase()
    : ''

  const settings = values?.settings
  const emailReceiptRecipients = settings?.emailReceipt?.recipients || []
  const userDefaultRecipients =
    emailReceiptUserModule?.userDefaults.recipients || []

  const recipients =
    emailReceiptRecipients.length > 0
      ? emailReceiptRecipients
      : userDefaultRecipients.length > 0
      ? userDefaultRecipients
      : userEmail

  const initialValues: EmailReceiptSettings = {
    active:
      settings?.emailReceipt?.active ??
      emailReceiptUserModule?.userDefaults.active ??
      false,
    recipients: recipients || [],
    subject:
      settings?.emailReceipt?.subject ||
      emailReceiptUserModule?.userDefaults.subject ||
      'New Lead',
    canShow: values.settings?.emailReceipt?.canShow ?? false, // canShow value is in Formik context due to useHandleAppsCompatibility hook result
  }

  const isEmailReceiptCompatible = values?.settings?.emailReceipt?.canShow

  const canShowEmailReceipt =
    initialValues &&
    isEmailReceiptCompatible &&
    isValid &&
    !isSubmitting &&
    Object.keys(errors).length === 0

  const handleSaveSettings = (emailReceiptValues: EmailReceiptSettings) => {
    setValues({
      ...values,
      settings: {
        emailReceipt: {
          ...values.settings?.emailReceipt,
          ...emailReceiptValues,
        },
      },
    })
  }

  return canShowEmailReceipt ? (
    <div style={{ marginTop: '3rem' }}>
      <TitleWrapper>
        <h2 style={{ margin: '0 1rem 1rem 0' }}>Email Receipt</h2>
        <IconLockWrapper>
          {!isAvailableToUser && (
            <StarterLabel small onClick={() => updateBridgeToPricing()}>
              <FaLock /> {planLabelName}
            </StarterLabel>
          )}
        </IconLockWrapper>
      </TitleWrapper>

      {isAvailableToUser || initialValues.active ? (
        <FormInner
          isBridgeEnabled={isBridgeEnabled}
          handleSubmit={handleSaveSettings}
          initialValues={initialValues}
        />
      ) : (
        <FeatureNotAvailable
          moduleReceiptId={moduleReceiptId}
          requiredLbPlanId={minimumPlanRequired}
          bridgeId={bridgeId}
        />
      )}
    </div>
  ) : null
}
