import React from 'react'
import { SignupLayout } from '@features/signup/SignupLayout'
import { Content } from '@features/signup/SignupContent/Content'
import { SignUpForm } from '@features/signup/SignupForm'
import { useTranslation } from 'react-i18next'
import signupGenericImage from '@assets/images/signup-generic.svg'

export const SignUp: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <SignupLayout
        hideLeftOnMobile
        leftSlot={
          <Content
            section={<img src={signupGenericImage} />}
            headerText={t('auth.signup.headline')}
            subText={t('auth.signup.subHeadline')}
          />
        }
        rightSlot={
          <SignUpForm
            title={t('auth.signup.formTitle')}
            showGoogleButton
            showFacebookButton
          />
        }
      />
    </>
  )
}
