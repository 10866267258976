import { Button } from '@components/Basic/ButtonNbe'
import React, { useState } from 'react'
import {
  BarRight,
  BarLeft,
  StatusBarBridgeStyled,
  StatusBarBridgeWrapper,
  StatusBarStatus,
  StatusBarMessage,
  BarCenter,
  CloseIcon,
} from './styled'
import { IoCloseOutline } from 'react-icons/io5'

interface StatusBarProps {
  text: string
  status: string
  onClick?: () => void
  buttonLabel?: string
  isLoading?: boolean
  allowManualClosing?: boolean
}

export const StatusBar: React.FC<StatusBarProps> = ({
  text,
  status,
  buttonLabel,
  onClick,
  isLoading,
  allowManualClosing,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  if (!isOpen) return null

  return (
    <StatusBarBridgeStyled>
      <StatusBarBridgeWrapper>
        {allowManualClosing && (
          <CloseIcon onClick={() => setIsOpen(false)}>
            <IoCloseOutline size={25} />
          </CloseIcon>
        )}

        <BarLeft />
        <BarCenter>
          <StatusBarStatus>{status}</StatusBarStatus>
          <StatusBarMessage>{text}</StatusBarMessage>
        </BarCenter>
        <BarRight>
          {buttonLabel ? (
            <Button
              $variant={'secondary'}
              onClick={onClick}
              disabled={isLoading}
              $loading={isLoading}
              style={{ fontSize: '.75rem', padding: '8px 18px' }}
            >
              {buttonLabel}
            </Button>
          ) : null}
        </BarRight>
      </StatusBarBridgeWrapper>
    </StatusBarBridgeStyled>
  )
}
