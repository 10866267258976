import React, { useRef, useState } from 'react'
import { BsQuestionCircle } from 'react-icons/bs'
import { ImCopy } from 'react-icons/im'
import ReactTooltip from 'react-tooltip'
import {
  InputWrapper,
  IconWrapper,
  InputField,
  CopyText,
  LabelWrapper,
} from './styled'

interface Props {
  label?: string
  inputValue?: string
  tooltip?: string
  docUrl?: string
  linkKey?: string
}

export const InputAutoSelectCopy: React.FC<Props> = ({
  label,
  inputValue,
  tooltip,
  docUrl,
  linkKey,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isCopied, setIsCopied] = useState(false)

  const handleCopyLink = () => {
    inputRef?.current?.select()
    document.execCommand('copy')
    setIsCopied(true)
  }

  return (
    <InputWrapper>
      <LabelWrapper>
        {label && <label>{label}</label>}
        {docUrl && (
          <a href={docUrl} target={'_blank'} rel={'noopener noreferrer'}>
            {linkKey}
          </a>
        )}
      </LabelWrapper>
      <InputField>
        <ReactTooltip
          id='url'
          place='top'
          type={'light'}
          effect='solid'
          className='customTooltipInput'
        >
          {inputValue}
        </ReactTooltip>
        <input
          data-tip
          data-for='url'
          ref={inputRef}
          type='text'
          value={inputValue}
          readOnly
          onClick={handleCopyLink}
          {...props}
        />
        <IconWrapper>
          {tooltip ? (
            <ReactTooltip
              id='url-info'
              place='bottom'
              type={'light'}
              effect='solid'
              className='customTooltipIcon'
            >
              {tooltip}
            </ReactTooltip>
          ) : null}
          <BsQuestionCircle size={20} data-tip data-for='url-info' />
          <ImCopy size={20} onClick={handleCopyLink} />
        </IconWrapper>
      </InputField>
      {isCopied && <CopyText>Copied</CopyText>}
    </InputWrapper>
  )
}
